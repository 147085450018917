import Vue from 'vue';
import * as constants from '../config/constants';
import serverHelpers from './server-helpers';
import logger from './logger';

export default {
    retrieveResearches(cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/research/researches').then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb);
        }, err => {
                logger.writeError('server-research retrieveResearches', err);
                serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
    documentreports(id, cb) {
        Vue.http.get(constants.SERVER_BASE_URL + '/research/documentreports?id=' + id).then((response) => {
            serverHelpers.analyseResponseAndCB(response, cb);
        }, err => {
            logger.writeError('server-research documentreports', err);
            serverHelpers.analyseResponseAndCB(err, cb);
        });
    },
}