import moment from 'moment';
import i18n from '../../../i18n'
import * as chConsts from './ChartConstants';

const AXIS_Y_INTERVAL = 10;

export default {
    /**
     * Возвращает строку с 2 датами отформатированными в соответствии с переданными параметрами
     * @param {object} row текущая строка с данными 
     * @param {boolean} isFull если false, будет выполняться проверка на совподения годов/месяцев в двух датах в строке
     * @param {string|Array} fieldPrefix часть имени или массив имен в строке для выбора нужных дат
     * @returns строка с 2 датами
     */
    formatPackageDates(row, isFull, fieldPrefix) {
        if(!row) return '';

        let start = row.periodstart;
        let end = row.periodend;
        if(fieldPrefix) {
            if(Array.isArray(fieldPrefix)) {
                start = row[fieldPrefix[0]];
                end = row[fieldPrefix[1]];
            } else {
                start = row[fieldPrefix + 'Start'];
                end = row[fieldPrefix + 'End'];
            }
        }
        
        if(row && start && end) {
            const df = 'DD.MM.YYYY'
            let one = moment(start);
            let two = moment(end);
            if(!isFull && one.isSame(two, 'year')) {
                if(one.isSame(two, 'month')) {
                    return one.format('DD') + ' - ' + two.format(df);
                } else {
                    return one.format('DD.MM') + ' - ' + two.format(df);
                }
            }
            return one.format(df) + ' - ' + two.format(df);
        } else {
            return ' - '; //'invalid dates'
        }
    },
    setSeriesLabelsSetup(option) {
        if(option.dataset.source && option.dataset.source.length > chConsts.BAR_TO_MATCH) {
            this.setSeriesLabelSetup(0, chConsts.LABEL_SETUP_HIDE, option);
            this.setSeriesLabelSetup(1, chConsts.LABEL_SETUP_HIDE, option);
            this.setSeriesLabelSetup(2, chConsts.LABEL_SETUP_HIDE, option);
            option.tooltip.show = true;
        } else {
            this.setSeriesLabelSetup(0, chConsts.LABEL_SETUP, option);
            this.setSeriesLabelSetup(1, chConsts.LABEL_SETUP, option);
            this.setSeriesLabelSetup(2, chConsts.LABEL_SETUP, option);
            option.tooltip.show = false;
        }
    },
    setSeriesLabelSetup(index, setup, option) {
        if(option.series && option.series[index]) {
            option.series[index].label = setup;
        }
    },
    copyOptions(yLbl, yMax) {
        const AXIS_SETUP_X = [
            {
                //нижняя ось Х
                type: 'category',
                axisLabel: {
                    formatter: function(name) {
                        return name;
                    },
                    fontSize: 16
                },
                // splitArea: {
                //     show: true,
                //     areaStyle: {
                //         color: ['white']
                //         // shadowColor: 'blue',
                //     }
                // },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed'
                    }
                }
            }, {
                //верхняя ось Х
                offset: 16, //поднимаем повыше надписи надписи во второй оси Х, что бы они не были прямо над столбцами 
                type: 'category',
                axisPointer: {
                    show: false,
                    type: 'none',
                    lineStyle: { color: 'white' }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                data: [],
                axisLabel: {
                    fontSize: 16
                }
            }
        ];

        return {
            textStyle: { fontSize: 16 },
            tooltip: {
                show: false,
                formatter: function(data) {
                    return data
                    // return `<span style="color: ${BAR_COLORS.OIL};">${data.data[BAR_FIELDS[this.key].OIL]}</span> <span style="color: ${BAR_COLORS.WATER};">${data.data[BAR_FIELDS[this.key].WATER]}</span>`;
                },
                borderColor: 'white'
            },
            dataset: {
                dimensions: ['port', 'oil', 'water'],
                source: []
            },
            xAxis: AXIS_SETUP_X,
            yAxis: this.getSetupForOneAxisY(yLbl, yMax),
            // yAxis: {
            //     nameGap: 25, //растояние от последнего значения по оси Y до надписи с названием этой оси (параметр name)
            //     name: yLbl,
            //     nameTextStyle: {
            //         align: 'right',
            //         rich: chConsts.AXIS_Y_NAME_STYLE
            //     },
            //     splitLine: {
            //         show: true,
            //         lineStyle: {
            //             type: 'dashed'
            //         }
            //     },
            //     axisLabel: {
            //         fontSize: 16
            //     },
            //     interval: AXIS_Y_INTERVAL
            // },
            // Declare several bar series, each will be mapped to a column of dataset.source by default.
            series: [
                chConsts.SERIES_OIL, chConsts.SERIES_WATER
            ],
            // INFO: сдвиг оси Y слева и справа на .. пикселей (те график будет рисоваться ближе к краям страницы)
            grid: {
                // left: '30px',
                // right: '30px'
            }
        }
    },
    setAxisYLabels(filterG, filterActive, options, max, maxAbsolute) {
        if(filterG) {
            if(filterActive == chConsts.BAR_FILTER.PERCENTS) {
                options.yAxis = this.getSetupForTwoAxisY(i18n.t('researchInfoForm.axisName4'), i18n.t('researchInfoForm.axisName5'), max);
            } else {
                options.yAxis = this.getSetupForTwoAxisY(i18n.t('researchInfoForm.axisName2'), i18n.t('researchInfoForm.axisName3'), maxAbsolute);
            }
        } else {
            if(filterActive == chConsts.BAR_FILTER.PERCENTS) {
                options.yAxis = this.getSetupForOneAxisY(i18n.t('researchInfoForm.axisName1'), max);
            } else {
                options.yAxis = this.getSetupForOneAxisY(i18n.t('researchInfoForm.axisName2'), maxAbsolute);
            }
        }
    },
    getSetupForTwoAxisY(yLbl1, yLbl2, maxVal) {
        if(!maxVal) {
            maxVal = 100;
        }
        return [{
            nameGap: 25, //растояние от последнего значения по оси Y до надписи с названием этой оси
            name: yLbl1,
            nameTextStyle: {
                align: 'right',
                rich: chConsts.AXIS_Y_NAME_STYLE
            },
            type: 'value',
            scale: true,
            interval: AXIS_Y_INTERVAL,
            // backgroundColor: 'red',

            max: maxVal,
            min: 0,
            // boundaryGap: [0.2, 0.2]
            // interval: 50,
            // axisLabel: {
            //     formatter: 'Дебит<br>жидкости,<br>м3/сут' //'{value} ml'
            // }
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            },
            axisLabel: {
                fontSize: 16
            }
        }, {
            nameGap: 25, //растояние от последнего значения по оси Y до надписи с названием этой оси
            name: yLbl2,
            nameTextStyle: {
                align: 'left',
                rich: chConsts.AXIS_Y_NAME_STYLE
            },
            type: 'value',
            scale: false,
            interval: AXIS_Y_INTERVAL,
            // max: maxVal,
            min: 0,
            // boundaryGap: false
            splitLine: {
                show: false,
                lineStyle: {
                    type: 'dashed'
                }
            },
            axisLabel: {
                fontSize: 16
            }
        }];
    },
    getSetupForOneAxisY(yLbl, maxVal) {
        return {
            nameGap: 25, //растояние от последнего значения по оси Y до надписи с названием этой оси
            name: yLbl,
            max: maxVal,
            nameTextStyle: {
                align: 'right',
                rich: chConsts.AXIS_Y_NAME_STYLE
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            },
            axisLabel: {
                fontSize: 16
            },
            interval: AXIS_Y_INTERVAL
        }
    },
    setDimensionsAndSeries(options, filterActive, compositionState, firstDimensionsValue) {
        if(!firstDimensionsValue) {
            firstDimensionsValue = 'port';
        }
        if(!filterActive) {
            filterActive = chConsts.BAR_FILTER.PERCENTS;
        }
        let series = [];
        let dimensions = [firstDimensionsValue];
        if(compositionState.filterOW) {
            series.push(chConsts.SERIES_LIQUID);
            dimensions.push(chConsts.BAR_FIELDS[filterActive].LIQUID);
        } else {
            if(compositionState.filterO) {
                series.push(chConsts.SERIES_OIL)
                dimensions.push(chConsts.BAR_FIELDS[filterActive].OIL)
            }
            if(compositionState.filterW) {
                series.push(chConsts.SERIES_WATER)
                dimensions.push(chConsts.BAR_FIELDS[filterActive].WATER)
            }
        }
        if(compositionState.filterG) {
            series.push(chConsts.SERIES_GAS)
            dimensions.push(chConsts.BAR_FIELDS[filterActive].GAS)
        }
        options.dataset.dimensions = dimensions;
        options.series = series;
    },
    defineCompositionLegendCheckedOptions(chartData, defaultOptions) {
        //INFO: checkedOptions[0] = false -- всегда, тк 0 элемент там -- это сумма 1 и 2, его всегда выбирают руками, устанавливаем 
        //      сразу, что бы в таком виде сразу передать в компонент, и не надо было бы потом двигать или копировать весь массив
        //INFO: "галочки" не снимаются, только ставятся, те если передано, что defaultOptions[1]=true, то даже, если нет данных, то
        //      этот элемент всё равно останется true
        let checkedOptions = defaultOptions ? defaultOptions : [false, false, false, false];
        if(chartData) {
            chartData.every(el => {
                if(el.oil > 0 && !checkedOptions[1]) {
                    checkedOptions[1] = true;
                }
                if(el.water > 0 && !checkedOptions[2]) {
                    checkedOptions[2] = true;
                }
                if(el.gas > 0 && !checkedOptions[3]) {
                    checkedOptions[3] = true;
                }

                if(checkedOptions[1] && checkedOptions[2] && checkedOptions[3]) {
                    return false; //если определили, что надо ставить все 3 чекбокса -- дальше искать смысла нет, выходим из цикла
                }
                return true;
            });
        }
        return checkedOptions;
    },
    calculateTotalForSecondAxisX(options, compositionState, filterActive) {
        options.xAxis[1].data = [];
        if(compositionState.filterO && compositionState.filterW && !compositionState.filterG && !compositionState.filterOW) {
            const dataFieldNameOil = chConsts.BAR_FIELDS[filterActive].OIL; //устанавливаем имя поля в данных, по которым будем выбирать нефть
            const dataFieldNameWater = chConsts.BAR_FIELDS[filterActive].WATER; //устанавливаем имя поля в данных, по которым будем выбирать воду
            options.dataset.source.forEach(el => {
                let one = el[dataFieldNameOil] ? el[dataFieldNameOil] : 0;
                let two = el[dataFieldNameWater] ? el[dataFieldNameWater] : 0;
                let v = Math.round((one + two) * 100) / 100;
                if(filterActive == chConsts.BAR_FILTER.PERCENTS) {
                    v += '%';
                }
                options.xAxis[1].data.push(v);
            });
        }
    }
}