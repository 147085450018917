import * as chConsts from './ChartConstants';

const MAX_FIELD_NAME = 'max';
const MAX_ABS_FIELD_NAME = 'maxAbsolute';
const DATA_FIELD_NAME_OIL = chConsts.BAR_FIELDS['PERCENTS'].OIL;
const DATA_FIELD_NAME_OIL_ABSOLUTE = chConsts.BAR_FIELDS['VALUES'].OIL;
const DATA_FIELD_NAME_WATER = chConsts.BAR_FIELDS['PERCENTS'].WATER;
const DATA_FIELD_NAME_WATER_ABSOLUTE = chConsts.BAR_FIELDS['VALUES'].WATER;
// const DATA_FIELD_NAME_GAS = chConsts.BAR_FIELDS['PERCENTS'].GAS;
const DATA_FIELD_NAME_GAS_ABSOLUTE = chConsts.BAR_FIELDS['VALUES'].GAS;

export default {
    calculateLiquid(el, oil, water, oilAbsolute, waterAbsolute) {
        if(!el.liquid) {
            el.liquid = Math.round((oil + water) * 100) / 100;
            el.liquidAbsolute = Math.round((oilAbsolute + waterAbsolute) * 100) / 100;
        }
    },
    initMaxsStrecture(struct) {
        if(!struct[MAX_FIELD_NAME]) {
            struct[MAX_FIELD_NAME] = 0;
            struct[MAX_ABS_FIELD_NAME] = 0;
        }
    },
    //выбираем максимальное значение по процентам и абсолютным значениям с усетом столбца Жидкость (сумма воды и нефти), иначе без Жидкости некореектно отображается максимальное значение на оси Y
    clculateMaxsForDocumentrepotMode(drMode, oil, water, gas, oilAbsolute, waterAbsolute, gasAbsolute, liquid, liquidAbsolute) {
        this.initMaxsStrecture(drMode);
        drMode[MAX_FIELD_NAME] = Math.max(drMode[MAX_FIELD_NAME], oil, water, gas, liquid);
        drMode[MAX_ABS_FIELD_NAME] = Math.max(drMode[MAX_ABS_FIELD_NAME], oilAbsolute, waterAbsolute, gasAbsolute, liquidAbsolute);
    },
    //устанавливаем максимальные значения для всех строк: сравниваем текущую строку (drMode) с уже провереннымы значениями(maxs)
    setMaxByRowForAllRows(maxs, drMode) {
        maxs[MAX_FIELD_NAME] = Math.max(maxs[MAX_FIELD_NAME], drMode[MAX_FIELD_NAME]);
        maxs[MAX_ABS_FIELD_NAME] = Math.max(maxs[MAX_ABS_FIELD_NAME], drMode[MAX_ABS_FIELD_NAME]);
    },
    //округление 2 полученных максимальных значений по одной строке (по процентам и в абсолютных значения) в соотвествии с LKZ-175
    roundMaxs(drMode) {
        drMode[MAX_FIELD_NAME] = this.calculateRoundedValue(drMode[MAX_FIELD_NAME]);
        drMode[MAX_ABS_FIELD_NAME] = this.calculateRoundedValue(drMode[MAX_ABS_FIELD_NAME]);
    },
    calculateRoundedValue(val) {
        if(val > 0) {
            //INFO-IMP: если надо, что бы функция и на  val = 50 возвращала 60, то заменить на формулу:
            //            Math.floor((50 + 10) / 10) * 10
            //          сейчас на 50 возвращает 50, а на 51-59 -- возвращает 60
            return Math.ceil(val / 10) * 10;
        }
        return 0
    },
    calculateMarker(el, drMode) {
        let oil = el[DATA_FIELD_NAME_OIL] ? el[DATA_FIELD_NAME_OIL] : 0;
        let water = el[DATA_FIELD_NAME_WATER] ? el[DATA_FIELD_NAME_WATER] : 0;
        // let gas = el[DATA_FIELD_NAME_GAS] ? el[DATA_FIELD_NAME_GAS] : 0;
        let oilAbsolute = el[DATA_FIELD_NAME_OIL_ABSOLUTE] ? el[DATA_FIELD_NAME_OIL_ABSOLUTE] : 0;
        let waterAbsolute = el[DATA_FIELD_NAME_WATER_ABSOLUTE] ? el[DATA_FIELD_NAME_WATER_ABSOLUTE] : 0;
        // let gasAbsolute = el[DATA_FIELD_NAME_GAS_ABSOLUTE] ? el[DATA_FIELD_NAME_GAS_ABSOLUTE] : 0;
        // let gasAbsolute = 0;
        if(el[DATA_FIELD_NAME_GAS_ABSOLUTE]) {
            //тк в абсолютных значениях надо отображать в тыс.м3/сут, то делим прилетевшее значение на 1000
            el[DATA_FIELD_NAME_GAS_ABSOLUTE] = el[DATA_FIELD_NAME_GAS_ABSOLUTE] / 1000;
            // gasAbsolute = el[DATA_FIELD_NAME_GAS_ABSOLUTE];
        }
        
        this.calculateLiquid(el, oil, water, oilAbsolute, waterAbsolute)

        // this.clculateMaxsForDocumentrepotMode(drMode, oil, water, gas, oilAbsolute, waterAbsolute, gasAbsolute, el.liquid, el.liquidAbsolute)
        this.clculateMaxsForDocumentrepotMode(drMode, oil, water, 0, oilAbsolute, waterAbsolute, 0, el.liquid, el.liquidAbsolute)
    }
}