<template>
    <div class="chart-row chart-card-one chart-card-one-three">
  
      <el-row class="chart-headers-row">
        <el-col :span="10">
          <CompositionLegend 
            :checkedOptions="initialCompositionState"
            @event-research-legend-set-value="legendSelectFilter"></CompositionLegend>
        </el-col>
      </el-row>

      <el-row class="charts-panel-root" :gutter="20"> <!-- charts-panel-root -- для объвления переменных css -->
        <!-- INFO: карточки с графиками -->
        <div class="charts-panel">
          <div
            v-for="(row, key) in chartCards" :key="row.portOrig" 
            v-show="intervals && intervals[key] && intervals[key].isSelected">
              <ChartCard 
                :chartData="row" :port="key" 
                :compositionState="compositionState" 
                :maxByRows="maxByRows"
                :cardTitle="formatPackageDates(intervals[key], true)">
              </ChartCard>
          </div>
        </div>
        
        <!-- INFO: карточка выбора интервалов(портов) -->
        <div class="chart-card intervals-card">
          <div class="header ports-header">{{$t('researchInfoForm.periods')}}</div>
          <div 
            v-for="interval in intervals" :key="interval.probepacket" 
            class="probepacket-select port-chb-select">
              <el-checkbox v-model="intervals[interval.probepacket].isSelected">
                <div class="port-chb-label">{{interval.probepacket}}</div>
                <div class="port-dates-label">{{formatPackageDates(interval, true)}}</div>
              </el-checkbox>
          </div>
        </div> 
      </el-row>
      
    </div>
</template>

<script>
import chHelpers from './ChartHelpers/ChartHelpers';
import DataTransformer from './ChartHelpers/ChartOneTwoDataTransformer';
import CompositionLegend from './componets/CompositionLegend.vue';
import ChartCard from './componets/ChartCard.vue';

export default {
  props: ['gsId', 'rows', 'maxByRows'],
  data() {
    return {
      intervals: {},
      chartCards: {}, //данные для отображения карточек с графиками
      compositionState: {}, //состояние выбрвнных чекбоксов на легенде
      initialCompositionState: [], //начальное состояние чекбоксов на легенде. расчитывается по данным и потом не должно меняться
    };
  },
  methods: {
    init() {
      const dataTransformer = new DataTransformer(this.rows, this.$t('researchInfoForm.portShort'));
      dataTransformer.transform();
      this.intervals = dataTransformer.intervals;
      this.chartCards = dataTransformer.chartCards;

      this.defineInitialCompositionState();
    },
    //по договоренности определяем какие чекбоксы будут выделены на легенде по набору данных для первой карточки
    defineInitialCompositionState() {
      const keys = Object.keys(this.chartCards);
      if(keys[0]) {
        this.initialCompositionState = chHelpers.defineCompositionLegendCheckedOptions(this.chartCards[keys[0]]);
      }
    },
    legendSelectFilter(compositionState) {
      this.compositionState = compositionState;
    },
    formatPackageDates(row, isFull) {
      return chHelpers.formatPackageDates(row, isFull);
    },
  },
  components: {
    CompositionLegend, 
    ChartCard
  },
  // mounted() {
  //   if(this.option && this.option.dataset) {
  //     this.option.dataset.source = [];
  //   }
  // },
  watch: {
    'rows': {
        handler: function (val, old) {
          if(val && val[0] && !old[0]) {
            this.init();
          } 
        }
    }
  }
};
</script>

<style src="./ResearchInfoChart.css" scoped></style>
<style scoped>
.chart-headers-row {
  margin: 10px 0 30px 55px;
}
.charts-panel-root {
  --card-height: 650px;
}
.charts-panel {
  min-width: 1550px;
  width: calc(100vw - 375px);
  min-height: var(--card-height);;
  float: left;
}
.intervals-card {
  width: 205px;
  height: var(--card-height);
  float: left;
}

.ports-header {
  width: 126px;
  margin: 30px auto;
}

.port-chb-select {
  margin-left: 28px;
}
.port-chb-label  {
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #31343d;
  display: inline-block;
}
.port-dates-label {
  width: 100px;
  /* height: 32px; */
  margin-left: 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #828589;
  display: inline-block;
  white-space: pre-line;
  vertical-align: top;
}
</style>