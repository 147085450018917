<template>
    <div class="research-grafical-view clickable" @click="showDialog">

        <!-- @event-research-grafical-view-total="setResearchGraficalViewTotal" -->
        <ResearchGraficalViewPoints style="display: inline-block;"
            :researchData="points" 
            :researchType="researchType" 
            :researchId="researchId"
            :pointWidth="pointWidth"
            :researchDataOrign="researchDataOrign"
            :researchProbe="researchProbe"
            @event-research-final-repotr-id="setFinalReportId"></ResearchGraficalViewPoints>

        <div v-if="!isOneDataArray() && cassettes && cassettes.length > 0" style="display: inline-block; width: 15px;"></div>
        
        <ResearchGraficalViewPoints style="display: inline-block;"
            :researchData="pointsCassetes" 
            researchType="cassettes" 
            :researchId="researchId"
            :pointWidth="pointWidth"></ResearchGraficalViewPoints>

        <div class="rgv-no-data" v-if="(!points || points.length == 0) && (!pointsCassetes || pointsCassetes.length == 0)">{{$t('researchDialog.noData')}}</div>
    </div>
</template>

<script>
import ResearchGraficalViewPoints from './ResearchGraficalViewPoints';

export default {
    //INFO: researchProbe -- передается для того, что если рисуем Лабораторные исследования то по клику на него надо рисовать данные из Отбора проб
  props: ['researchData', 'researchType', 'researchId', 'researchProbe', 'researchDataOrign', 'cassettes'],
  data() {
      return {
          pointWidth: 0,
          points: [],
          pointsCassetes: []
      }
  },
  methods: {
    init() {
        //INFO: в черточках в Операциях должны отображаться не все, а только те, что с level == 1, те количество черочек
        //      должно совпадать с количеством шариков в таблице. Фильтруем и работаем дальше с отфильтрованными данными!
        if(this.researchData) {
            if(this.researchType == 'operation') {
                this.points = this.researchData.filter(el => { return !el.isDeleted && el.level == 1 });
            } else {
                this.points = this.researchData;
            }
        }
        if(this.cassettes) this.pointsCassetes = this.cassettes.filter(el => { return !el.isDeleted && el.level == 1 });

        if(this.isOneDataArray()) {
            let rowLen = 230;
            if(this.points && this.points.length > 0) {
                if(this.points.length > 19) rowLen = 225;
                this.pointWidth = Math.round(rowLen / this.points.length);
            }
            if(this.pointsCassetes && this.pointsCassetes.length > 0) {
                if(this.pointsCassetes.length > 19) rowLen = 225;
                this.pointWidth = Math.round(rowLen / this.pointsCassetes.length);
            }
        } else {
            let rowLen = 220;
            if(this.pointsCassetes.length + this.points.length > 15) rowLen = 215;
            this.pointWidth = Math.round( rowLen / (this.pointsCassetes.length + this.points.length) );
        }
    },
    isOneDataArray() {
        if(!this.points) return true;
        if(!this.pointsCassetes) return true;
        if(this.points.length == 0) return true;
        if(this.pointsCassetes.length == 0) return true;
    },
    showDialog() {
        let rd = this.researchData;
        //INFO: в labtest может быть меньше черточек, чем надо отображать строчек в окне с информацией, поэтому 
        //  для labtest передаётся 2 набора данных: для отображения черточек и для передачи во всплывающее окно
        if(this.researchType == 'labtest') {
            rd = this.researchDataOrign;
        }
        this.$emit('event-research-grafical-show-dialog', {
            rt: this.researchType, 
            id: this.researchId, 
            data: rd, 
            cassettes: this.cassettes,
            lfId: this.lastFinishedId, 
            isFinished: this.isFinished, 
            isLastLabTestFinished: this.isLastLabTestFinished
        });
    },
    // setResearchGraficalViewTotal(params) {
    //     this.$emit('event-research-grafical-view-total', params);
    // },
    setFinalReportId(params) {
        this.$emit('event-research-final-repotr-id', params);
    },
  },
  created() {
    this.init();
  },
  components: {
      ResearchGraficalViewPoints
  }
}
</script>

<style scoped>
.research-grafical-view {
    width: 240px;
    height: 25px;
    margin-left: 20px;
    /* border: solid 1px orange; */
}
.rgv-no-data {
    color: #c0cad2;
    margin-top: 6px;
    float: left;
    font-size: 14px;
}
</style>