import { Notification } from 'element-ui';

function LoadingMsg(msg) {
    this.msg = null;

    this.show = function() {
        this.msg = Notification({
            dangerouslyUseHTMLString: true,
            message: `<i class="el-icon-loading"></i> ${msg}`,
            showClose: false,
            position: 'bottom-right',
            duration: 0,
            customClass: 'notification-custom-class'
        });
    }

    this.close = function() {
        this.msg.close();
    }
}

export default LoadingMsg;