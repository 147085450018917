function DataTransformer(dataRows, axisXLabelPart) {
    this.rows = dataRows;
    this.axisXLabelPart = axisXLabelPart;
    this.intervals = {};
    this.chartCards = {};
    this.currentPack = 0;

    this.transform = function() {
        this.rows.forEach((row) => {
            if(!row.isdeleted) {
                this.eachModes(row);
            }
        })
    }

    this.eachModes = function(row) {
        if(row && row.modeee && row.modeee.documentReport_Markers && row.modeee.documentReport_Markers.length > 0) {
            this.currentPack = row.probepacket;
            this.chartCards[this.currentPack] = [];
            this.setInterval(row);
            this.eachMarkers(row.modeee.documentReport_Markers);
            this.sortMarkers(this.chartCards[this.currentPack]);
        }
    }

    this.eachMarkers = function(markers) {
        markers.forEach(marker => {
            this.copyMarker(marker);
        })
    }

    this.copyMarker = function(marker) {
        let m = {};
        Object.assign(m, marker);
        m.portOrig = marker.port;
        m.port = this.axisXLabelPart + ' ' + marker.port;
        this.chartCards[this.currentPack].push(m);
    }

    this.setInterval = function(row) {
        this.intervals[this.currentPack] = { isSelected: true, probepacket: this.currentPack, periodstart: row.periodstart, periodend: row.periodend }
    }

    this.sortMarkers = function(markers) {
        markers.sort((a,b) => {
            return b.portOrig - a.portOrig;
        });
    }
}

export default DataTransformer