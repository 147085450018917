<template>
    <div :style="{width: 'max-content', margin: isShowByCenter ? '0 auto': '0'}">
        <span class="cf-header" style="margin-right: 10px;">{{title}}</span>
        <el-popover
        width="260"
        v-model="isShowRadoi">
            <el-radio v-for="(row, index) in rows" :key="row.probepacket" class="probepacket-select" v-show="!row.isdeleted"
                :label="row.probepacket" v-model="radio" @change="selectRadio(index)">
                <span class="row-probepacket-num">{{row.probepacket}}</span> {{formatPackageDates(row, true)}}</el-radio>
            <span class="link-text" slot="reference">
            <span v-show="isShowPacket">
                {{currentRow.probepacket}} (</span>{{formatPackageDates(currentRow, isFullDateFormatTitle)}}<span v-show="isShowPacket">)</span>
            </span>
        </el-popover>
    </div>
</template>

<script>
import chHelpers from '../ChartHelpers/ChartHelpers';
export default {
  props: ['title', 'rows' , 'isShowByCenter', 'isFullDateFormatTitle', 'isShowPacket'],
  data() {
    return {
        currentRow: {},
        isShowRadoi: false,
        radio: null
    }
  },
  methods: {
    init(docReports) {
        this.currentRow = docReports[0];
        this.radio = this.currentRow.probepacket; //номер пакета: 1, 2 или 3 и тд
    },
    selectRadio(index) {
        this.currentRow = this.rows[index];
        this.isShowRadoi = false;
        this.$emit('event-probepacket-selected', this.currentRow);
    },
    formatPackageDates(row, isFull) {
        return chHelpers.formatPackageDates(row, isFull);
    },
  },
  watch: {
    rows: {
        handler: function (val, old) {
            if(val && val[0] && !old[0]) {
                this.init(val)    
            }
        }
    }
  }
}
</script>

<style src="../ResearchInfoChart.css" scoped></style>