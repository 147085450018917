<template>
    <div class="survey-new-quater" v-show="isShowSurveyCard">
        <div>
            <SurveyNewCard 
                v-if="isShowSurveyCard"
                :surveyType="TYPE_REGULAR"
                :gsId="gsId"
                @event-survey-close-quater="$emit('event-survey-close-quater')">
            </SurveyNewCard>

        </div>
    </div>
</template>

<script>
import { TYPE_REGULAR } from './SurveyConstants';
import SurveyNewCard from './SurveyNewCard.vue';

export default {
    props: ['isShowSurveyCard', 'gsId'],
    data() {
        return {
            TYPE_REGULAR: TYPE_REGULAR
        };
    },
    methods: {
    },
    components: {
        SurveyNewCard
    },
}
</script>

<style scoped>
/* .row {
    width: calc(100% - 30px);
    height: 80px;
    margin-bottom: 30px;
} */
.survey-new-quater {
    z-index: 110;
    position: absolute;
    top: 215px;
    min-width: 1800px;
    width: 100%;
    min-height: 600px;
    height: calc(100vh - 218px);
    margin: 0px;
    padding: 0px;    
    background-color: #EFF4F6;
    /* border: 1px solid red; */
}
</style>