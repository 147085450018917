<template>
    <div v-if="pointWidth">
        <!-- <div v-if="researchData"> {{researchData.length}} </div> -->
        <div v-for="(point, index) in researchData" :key="point.no" 
            class="rgv-element" :style="{'width': pointWidth+'px'}">
            <!-- {{point.no}} -->
            <!-- {{point.id}} -->
            <!-- {{lastFinishedId}} -->
            <div class="rgv-underline" v-if="!point.isDeleted"
                :class="{'rgv-gray': isGray(point), 'rgv-green': isGreen(point), 'rgv-bleu': isBlue(point), 'rgv-red': isRed(point), 'rgv-orange': isOrange(point)}" >
                
                <img v-show="isFinished && index+1 == total" src="@/assets/img/lkz_ico_20.svg" alt="" class="rgv-last-finished">

                <!-- TODO: LKZ-147: отображение иконки частично выполненого исследования, вернуть когда напишут новый алгоритм отображения -->
                <!-- <img src="@/assets/img/lkz_ico_21.svg" alt="" class="rgv-last-finished" 
                    v-show="!isFinished && point.id == lastFinishedId"> -->
            </div>
        </div>
        <div v-if="total != 0" class="rgv-total" :class="{'rgv-finished': isFinished && total != 0, 'rgv-not-finished': !isFinished || total == 0 }">{{current}}/{{total}}</div>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import rHelpers from '../ResearchHelpers';


export default {
    //INFO: researchProbe -- передается для того, что если рисуем Лабораторные исследования то по клику на него надо рисовать данные из Отбора проб
  props: ['researchData', 'researchType', 'researchId', 'researchProbe', 'researchDataOrign', 'pointWidth'],
  data() {
      return {
          total: 0,
          current: 0,
          isFinished: false,
          isLastLabTestFinished: false,
          lastFinishedId: 0,
      }
  },
  methods: {
    init() {
        if(this.researchData) {
            this.researchData.sort((a, b) => {
                return a.no - b.no;
            });
            for(let el in this.researchData) {
                if(this.researchType == 'probe') {
                    //INFO: ищем id финального отчета. otchet_is_final == 'true' -- потому-что в данных оно лежит как строка
                    if(this.researchData[el].otchet_is_final == 'true' && this.researchData[el].otchet_id) {
                        this.$emit('event-research-final-repotr-id', {repId: this.researchData[el].otchet_id, id: this.researchId});                
                    }
                }
                if(!el.isDeleted) {
                    this.analyzeOperations(this.researchData[el], this.researchData[el-1]);
                }
            }
            if(this.total > 0) {
                this.isFinished = this.current == this.total;
            }
        }
        // if(this.researchType != 'cassettes') {
        //     this.$emit('event-research-grafical-view-total', {t: this.total, c: this.current, rt: this.researchType, id: this.researchId, isLastLabTestFinished: this.isLastLabTestFinished});
        // }
    },
    analyzeOperations(el, elPrev) {      
        this.total++;
        if(this.isFinishesStatus(el)) {
            this.current++;
            if(this.researchType == 'labtest') {
                if(this.researchData.length == this.total) {
                    this.isLastLabTestFinished = true;
                }
            }
        }
        //INFO: для установки значка последнего завершенного исследования
        if(elPrev) {
            if(!this.isFinishesStatus(el) && this.isFinishesStatus(elPrev)) {
                this.lastFinishedId = elPrev.id
            }
        }
    },
    isFinishesStatus(el) {
        if(this.researchType == 'operation' || this.researchType == 'cassettes') {
            if(el.status > 0 && el.status < 4) {
                return true;
            } 
            return false;
        } else if(this.researchType == 'probe') {
            return rHelpers.isProbeFinished(el);
        } else if(this.researchType == 'labtest') {
            return !!el.otchet_id;
        }
        
        return false;
    },
    isGray(el) {
        //INFO: IMPORTANT: теперь это СИНИЙ
        if(this.researchType == 'operation' || this.researchType == 'cassettes') {
            if(!this.isFinishesStatus(el) && el.status != 4) {
                return true;
            }
        } else if(this.researchType == 'probe') {
            if(!this.isFinishesStatus(el) && el.status != 4) {
                return true;
            }
        } else if(this.researchType == 'labtest' && !this.isFinishesStatus(el)) {
            return true;
        }
        return false;
    },
    isBlue(el) {
        //INFO: IMPORTANT: теперь это ЗЕЛЁНЫЙ
        if(!this.isFinished) {
            if(this.researchType == 'operation' || this.researchType == 'cassettes') {
                return  el.status == 1
            }
            if(this.researchType == 'probe' || this.researchType == 'labtest') {
                return this.isFinishesStatus(el)
            }
        }
        return false;
    },
    isGreen(el) {
        if(this.isFinished) {
            if(this.researchType == 'operation' || this.researchType == 'cassettes') {
                return el.status == 1
            }
            if(this.researchType == 'probe' || this.researchType == 'labtest') {
                return this.isFinishesStatus(el)
            }
        }
        return false;
    },
    isRed(el) {
        if(this.researchType == 'operation' || this.researchType == 'cassettes') {
            return el.status == 2
        }
        if(this.researchType == 'probe') {
            return el.status == 4
        }
        return false;
    },
    isOrange(el) {
        if(this.researchType == 'operation' || this.researchType == 'cassettes') {
            return el.status == 3 || el.status == 4 || el.status == 5
        }
        return false;
    }
  },
  created() {
    this.init();
  }
}
</script>

<style scoped>
.rgv-total {
    /* float: right; */
    margin-top: 18px;
    text-align: right;
    font-size: 13px;
    font-weight: 500;
}
.rgv-element {
    float: left;
    height: 10px;
}
.rgv-underline {
    height: 6px;
    border-radius: 3px;
    margin-right: 2px;
    margin-top: 12px;
}
.rgv-finished {
    color: #24c967;
}
.rgv-not-finished {
    color: #6377fa;
}
.rgv-last-finished {
    float: right;
    margin-top: -15px;
    width: 12px;
    height: 12px;
}
.rgv-gray {
    /* background-color: #c0cad2; */
    background-color: #6377fa;
}
.rgv-bleu {
    /* background-color: #6377fa; */
    background-color: #24c967;
}
.rgv-green {
    background-color: #24c967;
}
.rgv-orange {
    background-color: #feaf04;
}
.rgv-red {
    /* background-color: #f24841; */
    background-color: #feaf04;
}
</style>