<template>
    <div>
        <div class="geofield-group-title">
              <img src="@/assets/img/lkz_ico_14.svg" alt="-" class="rf-group-icon clickable" v-show="!isConclusionsHidden" @click="isConclusionsHidden = true" >
              <img src="@/assets/img/lkz_ico_13.svg" alt="+" class="rf-group-icon clickable" v-show="isConclusionsHidden" @click="isConclusionsHidden = false" >
              <div class="rf-group-title rf-group-title2" >{{$t('researchInfoForm.conclusions')}}</div>
          </div>
          
          <div v-show="chartData && chartData.length > 0 && !isConclusionsHidden" class="conclusions">  
            <el-row>
              <div style="margin-left: 10px;">
                <ProbePacketSelect :rows="chartData" :title="$t('researchDialog.operationTh5')" :isFullDateFormatTitle="true"
                  @event-probepacket-selected="probepacketSelected"></ProbePacketSelect>
              </div>
            </el-row>

            <el-row :gutter="20"> 
              <div class="pack-tab-content">
                <div v-for="pack in chartData" :key="pack.probepacket">
                  <div v-show="pack.probepacket == filterPack && !pack.isdeleted">
                      <div class="concl-header" v-if="pack.conclusions">{{$t('researchInfoForm.conclusionsH1')}}</div>
                      <!-- <ckeditor ref="wwwww" v-model="pack.conclusions" :config="editorConfig" :read-only="true"></ckeditor> -->
                      <div class="pack-content" v-if="pack.conclusions">{{pack.conclusions}}</div>
                      <div class="concl-header" v-if="pack.notes">{{$t('researchInfoForm.conclusionsH2')}}</div>
                      <div class="pack-content" v-if="pack.notes">{{pack.notes}}</div>
                      <div class="concl-header" v-if="pack.recommendations">{{$t('researchInfoForm.conclusionsH3')}}</div>
                      <div class="pack-content" v-if="pack.recommendations">{{pack.recommendations}}</div>
                  </div>
                </div>
              </div>
            </el-row>
          </div>
    </div>
</template>


<script>
import ProbePacketSelect from './componets/ProbePacketSelect.vue';

export default {
  props: ['chartData'],
  data() {
    return {
      isConclusionsHidden: false,
      filterPack: 1, //выбранный таб в разделе "Выводы и рекомендации"
      // editorConfig: {
      //   toolbar: [],
      //   enableContextMenu: false,
      //   resize_enabled: false,
      //   removePlugins: 'elementspath',
      //   uiColor: '#EFF4F6',
      //   allowedContent: true,
      //   contentsCss: [
      //     `@font-face {font-family: 'Inter'; src: url('${process.env.BASE_URL}assets/fonts/Inter/InterAll.ttf');}`,
      //     "body {font-size: 16px;font-family: 'Inter'; font-weight: 300;}"
      //   ]
      // }
    }
  },
  methods: {
    probepacketSelected(currentRow) {
      this.filterPack = currentRow.probepacket;
    },
    init() {
       //INFO-IMP: вызывать метод init в mounted() 
      //INFO: если в конфиге прописали лишний / в пути -- убираем его
      //IMPORTANT: если надо будет вернутся к использованию CKEditor -- раскоментировать строчку ниже и 2 строчки в main.js
      //           пока это всё не удаляю, что бы нормально всё оттестили и если не будет ошибок в преобразовании текста из
      //           ms word в обычный текст для отображения -- удалить этот код.
      // this.editorConfig.contentsCss[0] = this.editorConfig.contentsCss[0].replace('//', '/');
      
      if(this.chartData && this.chartData[0] && this.chartData[0].probepacket) {
        this.filterPack = this.chartData[0].probepacket;
      }
    }
  },
  components: {
    ProbePacketSelect
  },
  // mounted() {
  //   this.init();
  // }
  watch: {
      'chartData': {
          handler: function(val, old) {
              if(val && val[0] && !old[0]) {
                  this.init();
              }
          }
      }
  }
}
</script>

<style src="./Research.css" scoped></style>
<style src="./ResearchInfo.css" scoped></style>