<template>
    <div>
        <div class="research-row child">
            <img src="@/assets/img/lkz_ico_27.svg" alt="finrep" class="final-report-icon" v-show="finalReports[r.id]">
            <div class="final-report-icon final-report-icon-empty" v-show="!finalReports[r.id]"></div>
            
            <div class="rf-gs-title clickable" @click="showResearchInfo" v-show="rowType != 'partial'">{{r.gs_title}}</div>
            <div class="rf-gs-title-wl" v-show="rowType == 'partial'">
                <div style="margin: 0; text-align: center; width: 200px; font-size:14px;">{{$t('researchForm.th1')}}</div>
                <div class="rf-gs-title" style="margin: 0; text-align: center; width: 200px;">{{r.gs_title}}</div>
            </div>
            
            <div v-if="rowType != 'partial'" :class="[r.favorite_id ? 'rf-star-selected-bg' : 'rf-star-bg']" class="rf-star" @click="setFavorite(r.id, r.favorite_id)"></div>
            <div v-if="rowType == 'partial'" :class="[r.favorite_id ? 'rf-star-selected-bg' : 'rf-star-bg-header']" class="rf-star clickable" @click="setFavorite(r.id, r.favorite_id)" style="margin: 25px 12px 0 12px; width: 24px; height: 24px;"></div>

            <div class="rf-gsg_title" v-show="rowType != 'partial'">{{r.gsg_title}}</div>
            <div v-if="r.solution_type && r.solution_type.length > 0 " class="rf-solution" v-show="rowType != 'partial'" v-bind:class="{'rf-solution-size-1': r.solution_type.length < 18, 'rf-solution-size-2': r.solution_type.length >= 18 && r.solution_type.length < 30, 'rf-solution-size-3': r.solution_type.length >= 30}">{{r.solution_type}}</div>
            <div v-else class="rf-solution" v-show="rowType != 'partial'"></div>

            <div style="width: 140px;">
                <div class="investigation-status-icon">
                    <img v-if="!r.investigationstatus_id" src="@/assets/img/inv_status/lkz_ico_0.svg" alt="-"  >
                    <img v-if="r.investigationstatus_id == 1" src="@/assets/img/inv_status/lkz_ico_1.svg" alt="-" >
                    <img v-if="r.investigationstatus_id == 2" src="@/assets/img/inv_status/lkz_ico_2.svg" alt="-" style="margin: 0 0 10px" >
                    <img v-if="r.investigationstatus_id == 3" src="@/assets/img/inv_status/lkz_ico_3.svg" alt="-" style="margin: 0 0 10px" >
                    <img v-if="r.investigationstatus_id == 4" src="@/assets/img/inv_status/lkz_ico_4.svg" alt="-" >
                    <img v-if="r.investigationstatus_id == 5" src="@/assets/img/inv_status/lkz_ico_5.svg" alt="-" >
                    <img v-if="r.investigationstatus_id == 6" src="@/assets/img/inv_status/lkz_ico_6.svg" alt="-" >
                </div>
                <div class="investigation-status" :style="{'margin': calculateMargin}">
                    <div v-if="r.investigationstatus_id">{{$t('investigationStatus.'+r.investigationstatus_id)}}</div>
                    <div v-if="!r.investigationstatus_id">{{$t('investigationStatus.0')}}</div>
                </div>
            </div>

            <div class="rf-group rf-operation" :class="{'rf-group-active': researchDialogActive == r.id && researchDialogActiveType == 'operation'}">
            <!-- @event-research-grafical-view-total="setResearchGraficalViewTotal" -->
            <research-grafical-view 
                :researchData="r.documentsupervising_stages" :cassettes="r.documentsupervising_cassettes"
                researchType="operation" :researchId="r.id"
                @event-research-grafical-show-dialog="showResearchDialog"></research-grafical-view>
            </div>

            <div class="rf-group rf-probe" :class="{'rf-group-active': researchDialogActive == r.id && researchDialogActiveType != 'operation'}">
            <!-- @event-research-grafical-view-total="setResearchGraficalViewTotal" -->
            <research-grafical-view :researchData="r.packages" researchType="probe" :researchId="r.id"
                @event-research-grafical-show-dialog="showResearchDialog"
                @event-research-final-repotr-id="setFinalReportId"></research-grafical-view>
            </div>

            <div class="rf-group rf-research" :class="{'rf-group-active': researchDialogActive == r.id && researchDialogActiveType != 'operation'}">
            <!-- @event-research-grafical-view-total="setResearchGraficalViewTotal" -->
            <research-grafical-view :researchData="buildLabtestData()" :researchDataOrign="r.packages" researchType="labtest" :researchId="r.id" :researchProbe="r.packages"
                @event-research-grafical-show-dialog="showResearchDialog"></research-grafical-view>
            </div>

            <!-- TODO: IMPORTANT: при отображении процента выполнения удалить заглушку заполнитель выше! -->
            <!-- <div class="rf-total" v-if="isShowTotal">
                <vue-circle v-if="totals[r.id]"
                    :progress="totals[r.id].total"
                    :size="67" :thickness="8"
                    :reverse="false"
                    line-cap="round"
                    :fill="totalDiagramFill"
                    empty-fill="rgba(0, 0, 0, .1)"
                    :animation-start-value="0.0"
                    :start-angle="1.57"
                    insert-mode="append"
                    :show-percent="true">
                </vue-circle>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import VueCircle from 'vue2-circle-progress';
import server from "../../../api/server-model";
import ResearchGraficalView from "./ResearchGraficalView.vue";

export default {
    //INFO: r = данные из geo.research
    props: ['r', 'finalReports', 'isShowTotal', 'rowType', 'researchDialogActive', 'researchDialogActiveType'],
    computed: {
        calculateMargin() {
            if(this.r.investigationstatus_id == 1) {
                return '15px 0';
            }
            if(this.r.investigationstatus_id == 2) {
                if(this.$i18n.locale == 'en') {
                    return '15px 0'
                }
                return '5px 0'
            }
            if(this.r.investigationstatus_id == 3) {
                return '5px 0';
            }
            
            return '25px 0';
        },
        ...mapGetters(['currentUser']),
    },
    data() {
        return {
            totalDiagramFill : { gradient: ["#5668e0", "#5668e0", "#24c967", "#24c967"] }, //для градиента на проценте выполнения,
            isHideInfoPage: process.env.VUE_APP_HIDE_RES_INFO
        }
    },
    methods: {
        // setResearchGraficalViewTotal(params) {
        //     this.$emit('event-research-grafical-view-total', params);
        // },
        showResearchDialog(params) {
            this.$emit('event-research-grafical-show-dialog', params);
        },
        setFinalReportId(params) {
            this.$emit('event-research-final-repotr-id', params);   
        },
        showResearchInfo() {
            //INFO: IMPORTANT: isHideInfoPage - заглушка на время тестирования страницы с графиками, что бы она не отображалась на Проде. Потом удалить!!!
            if(!this.isHideInfoPage || this.isHideInfoPage.toLowerCase() == 'false') {
                this.$emit('event-research-show-full-info', this.r);
            }
        },
        setFavorite(gsId, fvId) {
          this.isLoading = true;
          if(fvId) {
            server.deleteModel('favorites', {id: fvId}, (isSuccess) => {
              this.isLoading = false;
              if(isSuccess) {
                this.$emit('event-research-grafical-set-favorite', gsId, null);
              }
            });
          } else {
            server.saveModel("favorites", {gs_id: gsId, account_id: this.currentUser.id }, (data) => {
                this.isLoading = false;
                if(data && data[0]) {
                    this.$emit('event-research-grafical-set-favorite', gsId, data[0].id);
                } 
            }, 'data');
          }
        },
        buildLabtestData() {
            //INFO: Проверяем, что если информация по отчетам прилетела в одной пачке (otchet_id будет совпадать у нескольких строк), то дубли 
            //  надо выкинуть, что бы кличество черточек совпадало с количество черточек совпадало с количеством строк в ecd.documentreports
            //  по данному geosplitid
            //INFO-IMP: расчитывать это на лету во время отрисовки -- не вариант, тк на основе количества записей расчитывается 
            let rowIndexesToRemove = []; //массив элементов, которые надо выкинуть данных для отображения
            let tempPack = this.r.packages; //просто, что бы удобнее было ссылаться в коде на this.r.packages
            if(tempPack) {
                tempPack.forEach((el, index) => {
                    let tempEl = tempPack[index + 1];
                    if(tempEl && tempEl.otchet_id && el.otchet_id) {
                        if(tempEl.otchet_id == el.otchet_id) {
                            //если у двух соседних элементов массива совпадают otchet_id, значит одну из строк надо удалить
                            rowIndexesToRemove.push(index);
                        }
                    }
                });
                if(rowIndexesToRemove.length > 0) {
                    //если нашли, что удалять, то удаляем лишние строки и возвращаем полученный массив для отображения в виде черточек
                    let labtestData = [];
                    Object.assign(labtestData, tempPack);
                    rowIndexesToRemove.forEach(index => {
                        labtestData.splice(index, 1);
                    })
                    return labtestData;
                }
            }
            return tempPack;
        }
    },
    components: {
        'research-grafical-view': ResearchGraficalView,
        // 'vue-circle': VueCircle,
    },
}
</script>

<style src="../Research.css" scoped></style>
<style scoped>
.rf-gs-title-wl {
    height: 60px;
}
.investigation-status {
    width: 100px;
    float: right;
    /* border: 1px solid greenyellow; */
}
.investigation-status-icon {
    width: 20px;
    height: 25px;
    margin: 25px 0;
    /* border: 1px solid greenyellow; */
    display: inline-block;
}
</style>