
export const TYPE_REGULAR = 10;
export const TYPE_QUATER = 20;
export const STATUS_FOR_USER = 10;
export const STATUS_FOR_ADMIN = 20;
export const VIEW_STATUS_NEW = 10;
export const VIEW_STATUS_VIEWED = 20;
export const SURVEY_SCORES = [10, 20, 30, 40, 50, 60, 70, 80];
export const SURVEY_SCORES_HELP = [1, 2, 3, 4, 5];
export const SURVEY_SCORES_COLORS = { 1: '#ef4a39', 2: '#f86d11', 3: '#c0d049', 4: '#82bb27', 5: '#2eae5e' };
export const SCORE_ICON_COLOR = '#ced3d4';
export const DIALOG_TYPE_CONFIRM = '1';
export const DIALOG_TYPE_GRATITUDE = '2';