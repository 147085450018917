<template>
    <div class="survey-new-quater" v-show="isShowSurveyCard">
        <div v-show="!isLoadingData">
            <div class="row">
                <div class="quater-header">{{ $t('survey.quaterHeader').replace('{quater}', survey.quater).replace('{year}', survey.year) }} 
                    <!-- 
                        INFO: v-if используем (тут и ниже), что бы дестроить SyrveyNewCard
                            если так не делать, то надо будет париться, что бы очищать поля ввода!
                    -->
                    <SurveyRatingHelp v-if="isShowSurveyCard"></SurveyRatingHelp> 
                </div>
                <div class="company-logo" :style="{'background-image': 'url('+logo+')'}"></div>
            </div>

            <SurveyNewCard v-if="isShowSurveyCard"
                :survey="survey"
                :surveyType="TYPE_QUATER"
                @event-survey-close-quater="closeDialog()"></SurveyNewCard>


        </div>
        <div v-loading="isLoadingData" style="margin: 25% auto"></div>
    </div>
</template>

<script>
import server from '../../api/server-model';
import SurveyNewCard from './SurveyNewCard.vue';
import SurveyRatingHelp from './SurveyRatingHelp.vue';
import { TYPE_QUATER } from './SurveyConstants';

export default {
    props: ['isShowSurveyCard', 'logo'],
    data() {
        return {
            survey: {},
            isLoadingData: true,
            TYPE_QUATER: TYPE_QUATER
        };
    },
    methods: {
        loadSurvey() {
            this.$store.dispatch('stopRetrievingNewSurveyCount');
            server.modelSpecific("survey", {method: 'showOneForUser'}, data => {
                this.isLoadingData = false;
                if(data && data[0]) {
                    this.survey = data[0];
                }
            });
            
        },
        closeDialog() {
            this.clear();
            this.$store.dispatch('retrieveNewSurveyCount');
            this.$emit('event-survey-close-quater');
        },
        clear() {
            this.survey = {};
            this.isLoadingData = true;
        }
    },
    components: {
        SurveyNewCard, SurveyRatingHelp
    },
    watch: {
        isShowSurveyCard: function(val) {
            if(val === true) {
                this.loadSurvey()
            }
        }
    }
}
</script>

<style scoped>
.row {
    width: calc(100% - 30px);
    height: 80px;
    margin-bottom: 30px;
    /* border: 1px solid hotpink; */
}
.survey-new-quater {
    z-index: 110;
    position: absolute;
    top: 0;
    min-width: 1800px;
    width: 100%;
    min-height: 900px;
    height: calc(100vh - 50px);
    margin: 0px;
    padding: 0px;    
    background-color: #EFF4F6;
    /* border: 1px solid red; */
}
.quater-header {
    margin: 20px 100px;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #25272f;
    float: left;
    /* border: 1px solid hotpink; */
}
.company-logo {
  width: 320px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  float: right;
  margin-top: 15px;
  /* border: 1px solid hotpink; */
}
</style>