
import moment from 'moment';

function DataTransformer(dataRows) {
    this.rows = dataRows;
    this.ports = {};
    this.chartCards = {};
    this.currentRowIndex = 0;

    this.transform = function() {
        this.rows.forEach((row, index) => {
            if(!row.isdeleted) {
                this.currentRowIndex = index;
                this.eachModes(row)
            }
        })
    }

    this.eachModes = function(row) {
        if(row && row.modeee) {
            this.eachMarkers(row.modeee.documentReport_Markers)
        }
    }

    this.eachMarkers = function(markers) {
        if(markers) {
            markers.forEach(marker => {
                this.setPort(marker);
                this.setRowByPorts(marker);
                this.transformRowToRowsByPorts(marker);
            })
        }
    }

    this.setPort = function(marker) {
        if(!this.ports[marker.port]) {
            this.ports[marker.port] = { isSelected: true, portNum: marker.port}
        }
    }
    this.setRowByPorts = function(marker) {
        if(!this.chartCards[marker.port]) {
            this.chartCards[marker.port] = [];
        }
    }

    this.transformRowToRowsByPorts = function(marker) {
        let m = {};
        Object.assign(m, marker);
        m.portOrig = marker.port;
        m.port = this.buildPortLabel();
        this.chartCards[marker.port][this.currentRowIndex] = m;
    }

    this.buildPortLabel = function() {
        const currentRow = this.rows[this.currentRowIndex];
        return moment(currentRow.periodstart).format('DD.MM.YY') + '\n' + moment(currentRow.periodend).format('DD.MM.YY')
    }
}

export default DataTransformer