<template>
    <div class="chart-card chart-single-card">
        <div class="header card-header">{{cardTitle}}</div>
        <v-chart class="chart" :option="option" autoresize ref="echart" />
    </div>
</template>

<script>
import VChart from "vue-echarts";
import { CanvasRenderer } from "echarts/renderers";
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DatasetComponent
} from "echarts/components";

import { BAR_FILTER } from '../ChartHelpers/ChartConstants';
import chHelpers from '../ChartHelpers/ChartHelpers';
import lmCalculator from '../ChartHelpers/LiquidAndMaxCalculator';

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DatasetComponent
]);

export default {
  props: ['chartData', 'port', 'compositionState', 'cardTitle', 'maxByRows'],
  data() {
    return {
      option: chHelpers.copyOptions(this.$t('researchInfoForm.axisName1')), // chHelpers.copyOptions() //chConsts.OPTIONS
      filterActive: BAR_FILTER.PERCENTS,
      maxs: {}
    };
  },
  methods: {
      init() {
        this.calculateMaxForAxisY();
        
        this.option.dataset.source = [];
        this.option.dataset.source = this.chartData;
        this.option.grid = {left: '110px', righr: '110px'};

        chHelpers.setSeriesLabelsSetup(this.option);
        this.setAxisYParams();
        this.setFirstAxisXFontSize();
      },
      setFirstAxisXFontSize() {
        if(this.chartData && this.chartData.length > 6) {
          let axisLabelRef;
          let fontSize = 12;
          
          if(this.option.xAxis && this.option.xAxis.axisLabel) {
            axisLabelRef = this.option.xAxis.axisLabel;
          } else {
            axisLabelRef = this.option.xAxis[0].axisLabel;
          }

          if(this.chartData.length > 10) {
            fontSize = 10;
          }
          axisLabelRef.fontSize = fontSize;
        }
      },
      calculateMaxForAxisY() {
        if(this.maxByRows) {
          //для второй вкладки максимус надо устанавливать на всех графиках одинаковый; 
          //  maxByRows -- расчет MAX по всем строкам, если его передали, то считать 
          //  индивидуально для каждого графика не надо.
          this.maxs = this.maxByRows;
          return
        }

        this.chartData.forEach(el => {
          lmCalculator.calculateMarker(el, this.maxs);
        })
        lmCalculator.roundMaxs(this.maxs);
      },
      changeChartView() {
        this.clearChartRef();
        chHelpers.setDimensionsAndSeries(this.option, this.filterActive, this.compositionState);
        this.setAxisYParams();
      },
      clearChartRef() {
        if(this.$refs.echart._isMounted) {
          this.$refs.echart.clear();
        }
      },
      setAxisYParams() {
        chHelpers.calculateTotalForSecondAxisX(this.option, this.compositionState, this.filterActive);
        chHelpers.setAxisYLabels(this.compositionState.filterG, this.filterActive, this.option, this.maxs.max, this.maxs.maxAbsolute);
      }
  },
  components: {
    VChart
  },
  mounted() {
    this.init();
  },
  watch: {
    compositionState: { 
      handler: function (val, old) {
        if(val && old) {
          this.changeChartView();
        } 
      }, 
      deep: true
    }
  }
}
</script>

<style src="../ResearchInfoChart.css" scoped></style>
<style scoped>
.chart-single-card {
  width: calc(50% - 15px);
  height: var(--card-height);
  margin: 0 15px 15px 0;
  float: left;
}
.card-header {
  width: 300px;
  margin: 30px 0 30px 93px;
}
</style>