import { render, staticRenderFns } from "./ResearchInfo.vue?vue&type=template&id=830de7aa&scoped=true&"
import script from "./ResearchInfo.vue?vue&type=script&lang=js&"
export * from "./ResearchInfo.vue?vue&type=script&lang=js&"
import style0 from "./Research.css?vue&type=style&index=0&id=830de7aa&scoped=true&lang=css&"
import style1 from "./ResearchInfo.css?vue&type=style&index=1&id=830de7aa&scoped=true&lang=css&"
import style2 from "./ResearchInfo.vue?vue&type=style&index=2&id=830de7aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "830de7aa",
  null
  
)

export default component.exports