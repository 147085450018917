<template>
    <div>
        <el-dialog
            class="survey-user-dialogs"
            :visible.sync="isShowDialog"
            width="527px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            append-to-body
            center>

            <img src="@/assets/img/lkz_ico_41.svg" alt="exit" class="del-dialog-img" v-show="dialogType == DIALOG_TYPE_CONFIRM">
            <img src="@/assets/img/lkz_ico_43.svg" alt="exit" class="del-dialog-img" v-show="dialogType == DIALOG_TYPE_GRATITUDE">

            <div class="del-dialog-title" v-show="dialogType == DIALOG_TYPE_CONFIRM">{{$t('survey.dialogTextSend')}}</div>
            <div class="del-dialog-title" v-show="dialogType == DIALOG_TYPE_GRATITUDE">{{$t('survey.dialogTextClose')}}</div>

            <div slot="footer" class="dialog-footer">
                <el-button round class="del-dialog-cancel" @click="$emit('event-survey-close-dialog')">{{$t('buttons.cancel')}}</el-button>
                <el-button type="primary" round @click="$emit('event-survey-confirm-dialog')" v-show="dialogType == DIALOG_TYPE_CONFIRM">{{$t('survey.dialogButtonSend')}}</el-button>
                <el-button type="primary" round @click="exit" v-show="dialogType == DIALOG_TYPE_GRATITUDE">{{$t('survey.dialogButtonClose')}} (0{{beforeCloseCount}})</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { DIALOG_TYPE_CONFIRM, DIALOG_TYPE_GRATITUDE } from './SurveyConstants';

export default {
    props: [
        'isShowDialog', 'dialogType'
    ], 
    data() {
        return {
            DIALOG_TYPE_CONFIRM: DIALOG_TYPE_CONFIRM,
            DIALOG_TYPE_GRATITUDE: DIALOG_TYPE_GRATITUDE,
            beforeCloseCount: 5,
            timer: null
        }
    },
    methods: {
        exit() {
            clearInterval(this.timer);
            this.$emit('event-survey-close-dialog-and-exit');
        },
        automateCloseDialog() {
            this.timer = setInterval(() => {
                this.check();
            }, 1000);
        },
        check() {
            this.beforeCloseCount--;
            if(this.beforeCloseCount === 0) {
                this.exit();
            }
        }
    },
    watch: {
        dialogType: function(val) {
            if(val == DIALOG_TYPE_GRATITUDE) {
                this.automateCloseDialog();
            }
        }
    }
}
</script>