//FIXME: должно быть 15. Для тестирования поставил 5 что бы проще было тестить
export const BAR_TO_MATCH = 15; //количество столбцов после которого должно поменяться отображение 
export const BAR_COLORS = {
    GAS: '#fcc300',
    OIL: '#f67b0a',
    WATER: '#5ec4f8',
    LIQUID: '#6da642'
};

export const BAR_FILTER = {
    PERCENTS: 'PERCENTS',
    VALUES: 'VALUES'
}

export const BAR_FIELDS = {
    PERCENTS: {
        GAS: 'gas',
        OIL: 'oil',
        WATER: 'water',
        LIQUID: 'liquid'
    },
    VALUES: {
        GAS: 'gasAbsolute',
        OIL: 'oilAbsolute',
        WATER: 'waterAbsolute',
        LIQUID: 'liquidAbsolute'
    }
};

//надписи над каждым столбиком на диаграме
export const LABEL_SETUP = {
    // normal: {
    //     show: true,
    //     position: 'top'
    // },
    show: true,
    position: 'top',
    fontSize: 12,
    formatter: function(val) {
        //определяем что брать -- проценты или абсолютные значения
        let dimensionName = val.dimensionNames.find(el => {
            return el.startsWith(val.seriesName)
        })
        if(val && val.data && val.data[dimensionName]) {
            return Math.round(val.data[dimensionName] * 100) / 100;
        }
        return '';
    }
}
export const LABEL_SETUP_HIDE = {
    show: false,
    // normal: { show: false },
    emphasis: { show: false }
};

//закругления столбиков на диаграме
const ITEM_STYLE = {
    // DEPRECATED normal and barBorderRadius
    // normal: {
    //     barBorderRadius: [3, 3, 3, 3]
    // }
    borderRadius: [3, 3, 3, 3]
}

export const AXIS_Y_NAME_STYLE = {
    sup: {
        fontSize: 12,
        padding: [0, 0, 5, 0]
    }
}

export const SERIES_GAS = { type: 'bar', color: BAR_COLORS.GAS, label: LABEL_SETUP, itemStyle: ITEM_STYLE, barWidth: 22, yAxisIndex: 1 }; //, showBackground: true, backgroundStyle: {color: 'rgba(180, 180, 180, 0.2)'}
export const SERIES_OIL = { type: 'bar', color: BAR_COLORS.OIL, label: LABEL_SETUP, itemStyle: ITEM_STYLE, barWidth: 22 };
export const SERIES_WATER = { type: 'bar', color: BAR_COLORS.WATER, label: LABEL_SETUP, itemStyle: ITEM_STYLE, barWidth: 22 };
export const SERIES_LIQUID = { type: 'bar', color: BAR_COLORS.LIQUID, label: LABEL_SETUP, itemStyle: ITEM_STYLE, barWidth: 22 };
