<template>
    <span>
        <span class="survey-notifier" v-bind:class="{ clickable: newSurveyCount > 0 }" @click="showPopup" >
            <img src="@/assets/img/lkz_ico_3.svg" alt="" v-show="newSurveyCount > 0">
            <img src="@/assets/img/lkz_ico_12.svg" alt="" v-show="newSurveyCount <= 0" class="disabled">
        </span>
        <div class="survey-notifier-popup" :style="cssVars" v-show="isShowDialog">
            <div class="survey-notifier-wrapper">
                <img class="survey-notifier-icon" src="@/assets/img/lkz_ico_76.svg" alt="board">
                <div class="survey-notifier-labels">
                    <div class="survey-notifier-label">{{$t('survey.notifyPhraseOne' + (isAdmin ? 'Admin' : ''))}}</div>
                    <div class="survey-notifier-label mt30px">{{$t('survey.notifyPhraseTwo' + (isAdmin ? 'Admin' : ''))}}</div>
                </div>
            </div>
            <div class="survey-notifier-buttons">
                <el-button round class="button-black-blue" @click="isShowDialog = false">{{$t('survey.notifyButtobCancel')}}</el-button>
                <el-button type="primary" round @click="isShowDialog = false; changeRout()">{{$t('survey.notifyButtobShow' + (isAdmin ? 'Admin' : ''))}}</el-button>
            </div>
        </div>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';

import utilites from '../../lib/utilites';

export default {
    props: ['marginRight'],
    computed: {
        ...mapGetters(['newSurveyCount', 'isAdmin']),
        cssVars() {
            return {
                '--right-margin': this.marginRight
            }
        }
    }, 
    data() {
        return {
            isShowDialog: false
        };
    },
    methods: {
        showPopup() {
            if(this.newSurveyCount == 0) return;

            this.isShowDialog = true
        },
        changeRout() {
            if(this.isAdmin) {
                if(!this.$router || !this.$router.currentRoute || this.$router.currentRoute.name != 'survey' ) {
                    utilites.goToRoute('survey');
                }
            } else {
                this.$emit('event-survey-show-quater');
            }
        }
    }
}
</script>

<style scoped>
.survey-notifier-popup {
    /* --right-margin: 400px; */
    position: absolute;
    right: var(--right-margin);
    margin-top: 10px;
    width: 650px;
    height: 286px;
    /* margin: 83px 229px 202px 283px;
    padding: 56px 39px 38px 51px; */
    opacity: 0.95;
    border-radius: 20px;
    background-color: #25272f;
    z-index: 10;
}
.survey-notifier-wrapper {
    margin: 56px;
}
.survey-notifier-icon {
    width: 82px;
    height: 102px;
    float: left;
}
.survey-notifier-labels {
    float: left;
    width: 420px;
    margin-left: 36px;
}
.survey-notifier-label {
    font-size: 18px;
    line-height: 1.67;
    text-align: left;
    color: #fff;
}
.mt30px {
    margin-top: 30px;
}

.survey-notifier-buttons {
    float: right;
    position: absolute;
    bottom: 36px;
    right: 36px;
}
</style>