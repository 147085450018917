<template>
    <span>
        <img src="@/assets/img/lkz_ico_73.svg" alt="?" class="clickable help-icon" @click="show" ref="surveyhelpicon">
        <div v-show="isShowHelp" class="help-dialog-wrapper" @click="isShowHelp = false">
            <div class="help-dialog" :style="cssVars">
                <div v-for="sc in helpScores" :key="sc">
                    <div style="color: white">
                        <el-rate :value="sc" :colors="colors" :disabled-void-color="scoreIconColor" disabled></el-rate>
                    </div>
                    <div class="survey-score-help-label" >{{$t('survey.scoreHelpLabel'+sc)}}</div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import { SURVEY_SCORES_HELP, SURVEY_SCORES_COLORS, SCORE_ICON_COLOR } from './SurveyConstants';
export default {
    computed: {
        cssVars() {
            return {
                '--top': this.top,
                '--left': this.left
            }
        }
    }, 
    data() {
        return {
            isShowHelp: false,
            top: 0,
            left: 0,
            helpScores: SURVEY_SCORES_HELP,
            colors:  SURVEY_SCORES_COLORS,
            scoreIconColor: SCORE_ICON_COLOR
        };
    },
    methods: {
        show() {
            this.top = this.$refs.surveyhelpicon.getBoundingClientRect().top + 32 + 'px';
            this.left = this.$refs.surveyhelpicon.getBoundingClientRect().left - 500 + 'px';
            this.isShowHelp = true
        }
    },
}
</script>

<style scoped>
.help-icon {
    width: 23px;
    height: 23px;
    margin-left: 13px;
}
.help-dialog-wrapper {
    z-index: 110;
    position: fixed;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    left: 0px;
    top: 0px;
}
.help-dialog {
    z-index: 110;
    position: absolute;
    top: var(--top);
    left: var(--left);
    width: 526px;
    height: 550px;
    margin: 7px 0 0;
    padding: 50px 51px 47px 52px;
    opacity: 0.95;
    border-radius: 15px;
    background-color: #25272f;
}
.survey-score-help-label {
    margin: 15px 0 45px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
</style>