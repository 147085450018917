<template>
    <!-- style="height: 550px;" :style="{'width': researchDialogParams && researchDialogParams.rt == 'operation' ? '680px' : '900px'}" -->
    <div>
        <div :class="tableBgStyle" v-if="researchData">
            <el-table class="rgv-table" :data="researchData" :default-sort = "{prop: 'no', order: 'descending'}"
                :cell-style="buildCellStyle" :header-cell-style="buildCellStyle" :height="height"
                :highlight-current-row="false"
                :span-method="rowSpanMethod"
                >

                <el-table-column prop="no" width="50">
                    <template slot-scope="scope">
                        <div :class="{'rgv-num-line': scope.$index != 0, 'rgv-num-line-green': isNumLineGreen(scope.$index - 1, scope.row.status)}"></div>
                        <div :class="{'rgv-num-line-hidden': scope.$index == 0}"></div>
                        <div class="rgv-dialog-oper-num"
                            :class="{'rgv-dialog-oper-num-success': scope.row.status == '6', 'rgv-dialog-oper-num-unsuccess': (scope.row.status != '6' && scope.row.status != '4') , 'rgv-dialog-oper-num-error': scope.row.status == '4'}"
                        >{{ researchData.length - scope.$index }}</div>
                        <div :class="{'rgv-num-line': scope.row.no != 1, 'rgv-num-line-green': isNumLineGreen(scope.$index + 1, scope.row.status)}"></div>
                        <div :class="{'rgv-num-line-hidden': scope.row.no == 1}"></div>
                        <img src="@/assets/img/lkz_ico_20.svg" alt="v" class="finisfed-iconnnnnnnn" v-if="isFinished && scope.$index == 0">
                    </template>
                </el-table-column>

                <el-table-column :label="$t('researchDialog.operationTh5')" width="190">
                    <template slot-scope="scope">
                        
                        <!-- INFO-IMP: раскоментарь следующую строку для выявления аномалий в отображении таблицы -->
                        <!-- <b>no:{{scope.row.no}}, otchet_id:{{scope.row.otchet_id}}, rowSpan:{{scope.row.rowSpan}}, id: {{scope.row.id}} <br></b> -->
                        
                        <!-- INFO: в зависимости от статуса отображаем или плановую или фактическую дату -->
                        <!-- INFO: отображаем 2 даты (старта и конца) только если они отличаются -->
                        <span v-if="scope.row.status != 2 && scope.row.status != 4">
                            {{ scope.row.start_probe_date | dateFormat(true)}}<span v-show="scope.row.start_probe_date != scope.row.end_probe_date"> - {{ scope.row.end_probe_date | dateFormat(true)}}</span>
                        </span>
                        <span v-if="scope.row.status == 2 || scope.row.status == 4">
                            {{ scope.row.start_planned_date | dateFormat(true)}}<span v-show="scope.row.start_planned_date != scope.row.end_planned_date"> - {{ scope.row.end_planned_date | dateFormat(true)}}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('researchDialog.operationTh6')" width="230">
                    <template slot-scope="scope">
                        <div class="rgv-table-icon">
                            <img src="@/assets/img/lkz_ico_64.svg" alt="w" class="rgv-status-icon" v-if="scope.row.status == '2'" style="width: 14px; height: 14px; margin: 0 14px 0 2px;">
                            <img src="@/assets/img/lkz_ico_18.svg" alt="w" class="rgv-status-icon" v-if="scope.row.status == '3'">
                            <img src="@/assets/img/lkz_ico_72.svg" alt="x" class="rgv-status-icon" v-if="scope.row.status == '4'"> <!-- style="width: 12px; height: 12px; margin: 0 16px 0 4px;" -->
                            <img src="@/assets/img/lkz_ico_63.svg" alt="w" class="rgv-status-icon" v-if="scope.row.status == '5'">
                            <img src="@/assets/img/lkz_ico_25.svg" alt="w" class="rgv-status-icon" v-if="scope.row.status == '6'">                            
                            <!-- <div class="rgv-status-icon" v-if="scope.row.status != '6' && scope.row.status != '4'"></div> -->
                        </div>
                        <div class="rgv-table-text">{{ $t('researchDialog.probeStstuses')[scope.row.status] }}</div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('researchDialog.operationTh7')" width="260">
                    <template slot-scope="scope">
                        <div class="rgv-table-icon">
                            <!-- 
                                scope.row.otchet_is_final != 'true' && scope.row.otchet_id 
                            -->
                            <img src="@/assets/img/lkz_ico_26.svg" alt="o" class="rgv-status-icon" v-if="isShowReport(scope.row)">
                            <img src="@/assets/img/lkz_ico_21.svg" alt="o" class="rgv-status-icon" v-if="isShowReportWaiting(scope.row)">
                            <!-- <div class="rgv-status-icon" v-if="!isShowReport(scope.row)"></div> -->
                        </div>
                        <div class="rgv-table-text" v-show="isShowReport(scope.row)">{{$t('researchDialog.repFinished')}} <div style="color: #828589; display: inline-block; padding-left: 5px;"> {{scope.row.docdate | dateFormat(true)}} </div> </div>
                        <div class="rgv-table-text" v-show="isShowReportWaiting(scope.row)">{{$t('researchDialog.repNotFinished')}}</div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('researchDialog.operationTh8')" width="180">
                    <template slot-scope="scope" v-if="isShowReport(scope.row)">
                        <div class="rgv-table-icon">
                            <img src="@/assets/img/lkz_ico_66.svg" alt="o" class="rgv-status-icon" v-if="scope.$index == 0 && isFinalReport">
                            <img src="@/assets/img/lkz_ico_4.svg" alt="o" class="rgv-status-icon" v-else>
                        </div>
                        <div class="rgv-table-text">
                            <a :href="generateDownloadUrl(scope.row)" target="_blank">{{$t('download')}}</a>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>


<script>
import * as constants from '../../../config/constants';
import rHelpers from '../ResearchHelpers';

export default {
  props: ['researchData', 'grayCell', 'height', 'tableBgStyle', 'isFinished', 'isFinalReport'],
  data() {
      return {
        //   researchData: [],
        //   dialogTitle: '', 
        //   isLoading: true,
          token: window.localStorage.getItem(constants.TOKEN_LOCALSTORAGE_NAME),
          sortedResearchData: []
      }
  },
  methods: {
    isNumLineGreen(index, status) {
        this.sortData();
        let nextRow = this.sortedResearchData[index];
        if(nextRow && nextRow.status) {
            if((nextRow.status == 4 || nextRow.status == 6) && (status == 4 || status == 6)) {
                return true;
            }
        }
        return false;
    },
    init() {
        if(this.researchData && this.researchData[0]) {
            this.sortData();
            this.calcRowspans();        
        }
    },
    sortData() {
        if(this.sortedResearchData.length == 0) {
            Object.assign(this.sortedResearchData, this.researchData);
            this.sortedResearchData.sort((a, b) => {
                return b.no - a.no;
            });
        }
    },
    calcRowspans() {
        let rdLen = this.sortedResearchData.length;
        for(let ind = 0; ind < rdLen; ind++) {
            let el = this.sortedResearchData[ind];
            let rowSpan = 1;
            if(el.otchet_id) {
                //перебираем все последующие элементы в массиве с данными, что бы проверить, будут ли совпадения у следующих 
                //  строчек по otchet_id с текущей. если совпадения есть, то строки в таблице объединять, поэтому rowSpan++
                //  тк объединять может надо будет не только две соседнии, но и больше, поэтому перебираем все последующие
                //  в цикле. как только определили, что совпадений нет -- выходим из цикла поиска совпадений: else { break; }
                for(let i = ind+1; i<rdLen; i++) {
                    if(el.otchet_id && el.otchet_id == this.sortedResearchData[i].otchet_id) {
                        rowSpan++;
                        ind++;
                        //если нашли совпадения по otchet_id у следующих строк ставим rowSpan=0, что бы её вообще выкинуть из таблицы.
                        this.sortedResearchData[i].rowSpan=0;
                    } else {
                        break;
                    }
                }
                if(rowSpan > 1) {
                    //если нашли совпадения, то у текущей строки устанавливаем сколько строк надо объединить в таблице
                    el.rowSpan=rowSpan;
                }
            }
        }
    },
    isShowReport(row) {
        if(row && rHelpers.isProbeFinished(row)) {
            return !!row.otchet_id;
        }
        return false;
    },
    isShowReportWaiting(row) {
        if(row && rHelpers.isProbeFinished(row)) {
            return !row.otchet_id;
        }
        return false;
    },
    generateDownloadUrl(row) {
        let url = constants.SERVER_BASE_URL + constants.SERVER_ATTACH_SEARCH_URL + this.token + '&gsid='+row.geosplitid + '&did='+row.otchet_id+'&dtype=FileOrigReport';
        return url;
    },
    buildCellStyle(cell) {
        let cs  = {};
        if(this.grayCell) {
            cs['background-color'] = '#EFF4F6';
        }
        if(cell.columnIndex == 0) {
            cs['border-bottom'] = 'none';
            // return {'border-bottom': 'none'};
        }
        return cs;
    },
    /* eslint-disable-next-line no-unused-vars */
    rowSpanMethod({ row, column, rowIndex, columnIndex }) {
        if(columnIndex == 3 || columnIndex == 4) {
            if(row.rowSpan > 0) {
                return {rowspan: row.rowSpan, colspan: 1}
            }
            if(row.rowSpan === 0) {
                return {rowspan: 0, colspan: 0}
            }
        }
    }
  },
  watch: {
    'researchData': {
        handler: function (val, old) {
          if(val && val[0] && !old[0]) {
            this.init(); //вариант для открытия в окне диалога
          } 
        }
    }
  },
  mounted() {
      this.init(); //вариант для открытия в исследовании с граффиками
  }
}
</script>

<style src="../ResearchInfoExt.css" scoped></style>