<template>
    <div style="margin-left: 30px;">
        <span class="research-info-form-h2-1 cf-header">{{$t('researchInfoForm.h2_1')}}</span>
        <span class="research-info-form-h2-2">
            <el-checkbox class="chb-liquid"
                v-model="chbs.filterOW" @change="setFilterByType('ow')"><span class="filter-profile-label">{{$t('researchInfoForm.t4')}}</span></el-checkbox>
            <el-checkbox class="chb-oil"
                v-model="chbs.filterO" @change="setFilterByType('o')"><span class="filter-profile-label">{{$t('researchInfoForm.t2')}}</span></el-checkbox>
            <el-checkbox class="chb-water"
                v-model="chbs.filterW" @change="setFilterByType('w')"><span class="filter-profile-label">{{$t('researchInfoForm.t3')}}</span></el-checkbox>
            <el-checkbox class="chb-gas"
                v-model="chbs.filterG" @change="setFilterByType('g')"><span class="filter-profile-label">{{$t('researchInfoForm.t1')}}</span></el-checkbox>
        </span>
    </div>
</template>

<script>
export default {
    props: ['checkedOptions'],
    data() {
        return {
            chbs: {
                filterOW: false,
                filterO: false,
                filterW: false,
                filterG: false
            }
        }
    },
    methods: {
        init() {
            if(this.checkedOptions && this.checkedOptions.length > 0) {
                this.chbs.filterOW = this.checkedOptions[0];
                this.chbs.filterO = this.checkedOptions[1];
                this.chbs.filterW = this.checkedOptions[2];
                this.chbs.filterG = this.checkedOptions[3];
            }
            this.$emit('event-research-legend-set-value', this.chbs);
        },
        setFilterByType(selectedType) {
            if(selectedType == 'ow') {
                this.chbs.filterO = false;
                this.chbs.filterW = false;
            } else if(selectedType == 'o' || selectedType == 'w') {
                this.chbs.filterOW = false;
            }
            this.$emit('event-research-legend-set-value', this.chbs);
        }
    },
    // mounted() {
    //     this.init();
    // },
    watch: {
        'checkedOptions': {
            handler: function(val, old) {
                if(val && val.length > 0 && old.length == 0) {
                    this.init();
                }
            }
        }
    }
}
</script>

<style scoped>
.research-info-form-h2-2 {
    display: inline-block;
    margin-left: 20px;
}
.filter-profile-label {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
</style>