<template>
    <div>
        <div class="survey-new-card">

            <div class="survey-new-card-regular-header" v-show="surveyType == TYPE_REGULAR">{{$t('survey.reqularHeader')}} <SurveyRatingHelp></SurveyRatingHelp></div>

            <el-row :gutter="100">
                <el-col :span="8">
                    <div v-for="scoreId in scoreLabelIds" :key="scoreId" 
                        class="survey-score-group" v-bind:class="{'survey-score-group-no-border': scoreId == 80}">
                        <div class="survey-score-label" >{{$t('survey.scoreLabel'+scoreId)}}</div>
                        <div class="survey-score-rate">
                            <el-rate v-model="ratings[scoreId]" :colors="colors"></el-rate>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <FormInputComponent :title="$t('survey.contactLabel')" v-bind:value.sync="contact_name" :cw="inputWidth" isRequired="true"></FormInputComponent>
                    <FormInputComponent :title="$t('profileForm.title')" v-bind:value.sync="job_title" :cw="inputWidth" isRequired="true"></FormInputComponent>
                    <FormInputComponent :title="$t('profileForm.phone')" v-bind:value.sync="phone" :cw="inputWidth" ></FormInputComponent>
                    <FormInputComponent title="E-mail" v-bind:value.sync="email" :cw="inputWidth" ></FormInputComponent>
                </el-col>
                <el-col :span="8">
                    <div class="survey-recomendations">
                        <div class="survey-info-label">{{$t('survey.labelRecomendations')}}</div>
                        <textarea v-model="description" class="survey-recomendations-text"></textarea>
                    </div>
                </el-col>
            </el-row>
            
        </div>

        <div class="survey-buttons-wrapper">
            <el-button round class="button-gray" @click="closeDialog">{{$t('buttons.cancel')}}</el-button>
            <el-button type="primary" round 
                :disabled="isLoading || isSaveDisabled" 
                :loading="isLoading"
                @click="confirmSave">
                    {{$t('survey.buttonSend')}}
            </el-button>
        </div>

        <SurveyUserDialogs :isShowDialog="isShowDialog" :dialogType="dialogType" 
            @event-survey-close-dialog="hideUserDialog"
            @event-survey-close-dialog-and-exit="closeDialog"
            @event-survey-confirm-dialog="save">
        </SurveyUserDialogs>

    </div>
</template>

<script>
import server from '../../api/server-model';
import { SURVEY_SCORES, SURVEY_SCORES_COLORS, STATUS_FOR_ADMIN, VIEW_STATUS_NEW, DIALOG_TYPE_CONFIRM, 
    DIALOG_TYPE_GRATITUDE, TYPE_REGULAR, TYPE_QUATER } from './SurveyConstants';
import FormInputComponent from "@/components/FormInputComponent.vue";
import SurveyRatingHelp from './SurveyRatingHelp.vue';
import SurveyUserDialogs from "./SurveyUserDialogs.vue";

export default {
    props: ['survey', 'surveyType', 'gsId'],
    computed: {
        isSaveDisabled() {
            if(!this.contact_name || !this.job_title) {
                return true;
            }
            for(let i = 0; i < SURVEY_SCORES.length; i++) {
                if(this.ratings[SURVEY_SCORES[i]] == 0) {
                    return true;
                }
            }
            SURVEY_SCORES.forEach(k => {
                return this.ratings[k] === 0;
            })
            return false;
        },
    },
    data() {
        return {
            ratings: {},
            contact_name: '',
            job_title: '',
            phone: '',
            email: '',
            description: '',
            colors:  SURVEY_SCORES_COLORS,
            scoreLabelIds: SURVEY_SCORES,
            TYPE_REGULAR: TYPE_REGULAR,
            inputWidth: '90%',
            isLoading: false,
            isShowDialog: false,
            dialogType: 0
        };
    },
    methods: {
        hideUserDialog() {
            this.$store.commit('setGuiIsComponentChanged'); //INFO: Т.к. использовали FormInputComponent, а он при изменении генерит событие запрещающее переход на другой роут, то отменяем запрет на смену роута
            this.isShowDialog = false;
            this.dialogType = 0;
        },
        confirmSave() {
            this.isShowDialog = true;
            this.dialogType = DIALOG_TYPE_CONFIRM;
        },
        save() {
            this.hideUserDialog();
            this.isLoading = true;
            this.$store.commit('setGuiIsComponentChanged'); //INFO: Т.к. использовали FormInputComponent, а он при изменении генерит событие запрещающее переход на другой роут, то отменяем запрет на смену роута
            const m = {
                id: this.surveyType == TYPE_QUATER ? this.survey.id : null,
                contact_name: this.contact_name,
                job_title: this.job_title,
                phone: this.phone,
                email: this.email,
                description: this.description,
                survey_type: this.surveyType,
                survey_status: STATUS_FOR_ADMIN,
                view_status: VIEW_STATUS_NEW,
                ratings: this.ratings,
                saveRatings: true, //INFO: указываем, что сделать после сохранения
                geosplit_id: this.surveyType == TYPE_REGULAR ? this.gsId : null
            }
            server.saveModel("survey", m, (isSuccess) => {
                this.isLoading = false;
                if(isSuccess) {
                    this.isShowDialog = true;
                    this.dialogType = DIALOG_TYPE_GRATITUDE;
                }
            }, 'isSuccess');
        },
        closeDialog() {
            this.hideUserDialog();
            this.$emit('event-survey-close-quater');
        },
    },
    components: {
        FormInputComponent, SurveyUserDialogs, SurveyRatingHelp
    }
}
</script>

<style src="./SurveyInfo.css" scoped></style>
<style scoped>
.survey-new-card {
    width: calc(100% - 21px);
    height: 577px;
    box-sizing: border-box;
    padding: 62px 77px;
    border-radius: 20px;
    box-shadow: 0px 2px 20px 0 rgba(155, 172, 184, 0.5);
    background-color: #fff;
}
.survey-new-card-regular-header {
    font-size: 30px;
    font-weight: 300;
    color: #25272f;
    margin: -15px 0 15px 0;
}
.survey-score-group {
    width: 100%;
}
.survey-recomendations {
    margin-left: 30px;
}

.survey-recomendations-text {
    resize: none;
    width: 100%;
    height: 390px;
    border-radius: 6px;
    border: solid 1px #c5cace;
    outline: none;
}

.survey-buttons-wrapper {
    margin: 21px;
    float: right;
}
</style>