<template>
    <div class="command-card">
      <div class="command-title bold">{{$t('labels.command')}}</div>
      <div class="command-list">
        <div v-for="empl in empls" :key="empl.id" class="command-row">
          <!-- {{empl.title}} {{empl.title_en}} -->
          <div class="user-avatar">
            <img :src="empl.avaUrl" alt="" class="user-avatar-img">
          </div>
          <div class="user-info">
            <div class="user-name">
              <el-tooltip class="item" effect="dark" placement="bottom-start">
                <div slot="content">
                  <div class="fio" v-if="$i18n.locale == 'ru'">{{empl.title}}</div>
                  <div class="fio" v-if="$i18n.locale == 'en'">{{empl.title_en}}</div>
                  <div class="phone">{{empl.phone}}</div>
                  <div><a :href="'mailto:'+empl.email" class="mailto">{{empl.email}}</a></div>
                </div>

                <span class="user-name-title" v-if="$i18n.locale == 'ru'">{{getUserLastname(empl.title, 0)}}<br>{{getUserLastname(empl.title, 1)}}</span>
                <span class="user-name-title" v-if="$i18n.locale == 'en'">{{getUserLastname(empl.title_en, 0)}}<br>{{getUserLastname(empl.title_en, 1)}}</span>

              </el-tooltip>
            </div>
            <div class="user-post" v-if="$i18n.locale == 'ru'">{{empl.post}}</div>
            <div class="user-post" v-if="$i18n.locale == 'en'">{{empl.post_en}}</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import server from '../api/server-model';
import * as constants from '../config/constants';

export default {
    computed: mapGetters(['currentUser']), 
    data() {
        return {
            empls: [],
            isLoading: false,
            token: window.localStorage.getItem(constants.TOKEN_LOCALSTORAGE_NAME)
        };
    },
    methods: { 
      retrieveUsers() {
          this.isLoading = true;
          let m = {
              user_id: this.currentUser.id,
              limit: 1000,
              orderBy: 'sort_order, title'
          }
          server.searchByModel('employee', m, (data) => {
            this.isLoading = false;
            if(Array.isArray(data)) {
              this.empls = data;
              this.empls.forEach(el => {
                el.avaUrl = constants.SERVER_BASE_URL + constants.SERVER_ATTACH_URL + el.attach_id + '&t=' + this.token
              });
            } 
          });
      }, 
      getUserLastname: function(user, index) {
        if(user &&  typeof user == 'string') {
          let arr = user.split(' ');
          return arr[index];
        }
        return '';
      }
    },
    created() {
        this.retrieveUsers();
    }
}
</script>

<style scoped>
.command-card {
  width: 280px;
  min-height: 660px;
  margin: 30px 20px 30px 0;
  padding: 0 8px 1px;
  border-radius: 25px;
  float: right;
  box-shadow: 0px 2px 20px 0 rgba(155, 172, 184, 0.5);
  background-color: #ffffff;
}

.command-title {
  width: 122px;
  height: 27px;
  margin: 34px 0 32px 45px;
  font-size: 30px;
  font-weight: 300;
  color: #25272f;
}

.command-list {
  width: 260px;
  margin: 20px 10px 20px 15px;
  height: 620px;
  /* border: 1px solid blue; */
  overflow-y: auto;
  overflow-x: hidden;
}

.command-row {
  width: 258px;
  height: 80px;
  /* border: 1px solid red; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.user-avatar {
  width: 70px;
  height: 70px;
  float: left;
}
.user-avatar-img {
  width: 70px;
  height: 70px;
  border-radius: 30px;
}
.user-info {
  float: left;
  margin-left: 16px;
}
.user-name {
  height: 40px;
  width: 166px;
  /* border: 1px solid; */
  font-size: 14px;
  color: #25272f;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
}
.user-name-title {
  border-bottom: 1px dashed #24c967;
}
.user-post {
  width: 166px;
  height: 30px;
  margin-top: 5px;
  font-size: 11px;
  color: #828589;
  /* border: 1px solid green; */
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
}
.mailto {
  color: white;
  text-decoration: none;
  border-bottom: 1px dashed #24c967;
}
.phone {
  font-size: 14px;
  margin-bottom: 7px;
}
.fio {
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 7px;
}
</style>