<template>
    <!-- style="height: 550px;" :style="{'width': researchDialogParams && researchDialogParams.rt == 'operation' ? '680px' : '900px'}" -->
    <div :class="{'operation-tables-in-dialog': height}">
        <div :class="tableBgStyle"  v-if="isRebuildFinished">

            <div
                v-for="operationNo in operationsPropantKeys" :key="operationNo">
                <div v-if="sortedResearchData.length > 0">
                    <div class="dailog-subheader">{{$t('researchDialog.titleProppane')}} 
                        <span v-if="operationsPropantKeys.length + operationsCassettesKeys.length > 1">({{$t('researchDialog.operationTitleShort')}} {{operationNo}})</span>
                    </div>
                    <el-table class="rgv-table" 
                        :data="operationsPropant[operationNo]"
                        :cell-style="buildCellStyle" :header-cell-style="buildCellStyle" :height="tableHeight"
                        :highlight-current-row="false"
                        :span-method="rowSpanMethod"
                        >
                        <el-table-column prop="no" width="100" :label="$t('researchDialog.operationTh0')">
                            <template slot-scope="scope">
                                <!-- ВЕРХНЯЯ ПОЛОСА НАД ШАРИКОМ -->
                                <div 
                                    :class="{'rgv-num-line': scope.$index != 0, 'rgv-num-line-green': isNumLineGreen(scope.$index - 1, scope.row.status, 'operationsPropant', operationNo)}"
                                    :style="{height: scope.row.rowSpan > 1 ? 11*(scope.row.rowSpan+(scope.row.rowSpan/1.5))+'px' : '11px'}">
                                </div>
                                <div :class="{'rgv-num-line-hidden': scope.$index == 0}"></div>
                                <!-- ВЕРХНЯЯ ПОЛОСА НАД ШАРИКОМ -->

                                <!-- ШАРИК -->
                                <div
                                    :class="{'rgv-dialog-oper-num': scope.row.stageNo, 'rgv-dialog-oper-num-line': !scope.row.stageNo, 'rgv-dialog-oper-num-success': scope.row.status == 1, 'rgv-dialog-oper-num-unsuccess': scope.row.status == 0 || scope.row.status == 3, 'rgv-dialog-oper-num-error': scope.row.status == 2 || scope.row.status == 4 || scope.row.status == 5}"
                                >
                                {{scope.row.stageNo}} 
                                <!-- {{ researchData.length - scope.$index }} -->
                                </div>
                                <!-- ШАРИК -->

                                <!-- НИЖНЯЯ ПОЛОСА ПОД ШАРИКОМ -->
                                <div v-if="!scope.row.rowSpan || researchData.length > scope.$index + scope.row.rowSpan"
                                    :class="{'rgv-num-line': researchData.length - scope.$index != 1, 'rgv-num-line-green': isNumLineGreen(scope.$index + 1, scope.row.status, 'operationsPropant', operationNo)}"
                                    :style="{height: scope.row.rowSpan > 1 ? 11*(scope.row.rowSpan+(scope.row.rowSpan/1.5))+'px' : '11px'}">
                                </div>
                                <!-- <div :class="{'rgv-num-line-hidden': researchData.length - scope.$index == 1}"></div> -->
                                <div 
                                    :class="{'rgv-num-line-hidden': researchData.length - scope.$index == 1}"
                                    :style="{height: scope.row.rowSpan > 1 && researchData.length == scope.$index + scope.row.rowSpan ? 11*(scope.row.rowSpan+(scope.row.rowSpan/1.5))+'px' : '0'}">
                                </div>
                                <!-- НИЖНЯЯ ПОЛОСА ПОД ШАРИКОМ -->

                                <img src="@/assets/img/lkz_ico_20.svg" alt="v" class="finisfed-iconnnnnnnn" v-if="isFinished && scope.$index == 0">
                            </template>
                        </el-table-column>

                        <el-table-column :label="$t('researchDialog.operationTh1')" width="150">
                            <template slot-scope="scope">
                                {{ scope.row.executionDate | dateFormat(true)}} 
                                
                                <!-- INFO-IMP: DEBUG -->
                                <!--                                 
                                <br> rowSpan={{scope.row.rowSpan}} {{scope.$index + scope.row.rowSpan}}
                                <br> {{scope.$index}} 
                                -->

                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('researchDialog.operationTh2')" width="150">
                            <template slot-scope="scope">
                                <div class="rgv-table-icon">
                                    <img src="@/assets/img/lkz_ico_26.svg" alt="v" class="rgv-status-icon" v-if="scope.row.status==1">
                                    <!-- <img src="@/assets/img/lkz_ico_16.svg" alt="x" class="rgv-status-icon" v-if="scope.row.status==2" style="width: 12px; height: 12px; margin: 0 16px 0 4px;"> -->
                                    <img src="@/assets/img/lkz_ico_72.svg" alt="!" class="rgv-status-icon" v-if="scope.row.status==2 || scope.row.status==3 || scope.row.status==4 || scope.row.status==5">
                                    <div class="rgv-status-icon" v-if="scope.row.status == 0"></div>
                                </div>
                                <div class="rgv-table-text">{{ $t('researchDialog.operationStatuses')[scope.row.status]}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="injectedToGeosplit" :label="$t('researchDialog.operationTh3')" width="210"></el-table-column>
                        <el-table-column prop="signature" :label="$t('researchDialog.operationTh4')" width="210"></el-table-column>

                        <el-table-column :label="$t('researchInfoForm.str7')" v-if="files && files.length > 0">
                            <template slot-scope="scope">
                                <span v-for="f in generateActDownloadUrls(scope.row)" :key="f">
                                    <div class="rgv-table-text" v-if="f">
                                        <a :href="f" target="_blank" class="zz"><img src="@/assets/img/lkz_ico_4.svg" alt="o" class="rgv-status-icon link-text"></a>
                                    </div>
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div
                v-for="operationNo in operationsCassettesKeys" :key="operationNo">
                <div v-if="operationsCassettes[operationNo].length > 0">
                    <div class="dailog-subheader" style="padding: 30px 0 15px">{{$t('researchDialog.titleTappes')}} 
                        <span v-if="operationsPropantKeys.length + operationsCassettesKeys.length > 1">({{$t('researchDialog.operationTitleShort')}} {{operationNo}})</span>
                    </div>
                    <el-table class="rgv-table"
                        :data="operationsCassettes[operationNo]" 
                        :cell-style="buildCellStyle" :header-cell-style="buildCellStyle" :height="tableHeight"
                        :highlight-current-row="false"
                        :span-method="rowSpanMethod"
                        >
                        <el-table-column prop="no" width="100" :label="$t('researchDialog.operationTh0')">
                            <template slot-scope="scope">
                                <!-- ВЕРХНЯЯ ПОЛОСА НАД ШАРИКОМ -->
                                <div 
                                    :class="{'rgv-num-line': scope.$index != 0, 'rgv-num-line-green': isNumLineGreen(scope.$index - 1, scope.row.status, 'operationsCassettes', operationNo)}"
                                    :style="{height: scope.row.rowSpan > 1 ? 11*(scope.row.rowSpan+(scope.row.rowSpan/1.5))+'px' : '11px'}">
                                </div>
                                <div :class="{'rgv-num-line-hidden': scope.$index == 0}"></div>
                                <!-- ВЕРХНЯЯ ПОЛОСА НАД ШАРИКОМ -->

                                <!-- ШАРИК -->
                                <div :class="{'rgv-dialog-oper-num': scope.row.stageNo, 
                                            'rgv-dialog-oper-num-line': !scope.row.stageNo, 
                                            'rgv-dialog-oper-num-success': scope.row.status == 1, 
                                            'rgv-dialog-oper-num-unsuccess': scope.row.status == 0 || scope.row.status == 3, 
                                            'rgv-dialog-oper-num-error': scope.row.status == 2 || scope.row.status == 4 || scope.row.status == 5}"
                                >
                                {{scope.row.stageNo}}
                                </div>
                                <!-- ШАРИК -->

                                <!-- НИЖНЯЯ ПОЛОСА ПОД ШАРИКОМ -->
                                <div v-if="!scope.row.rowSpan || operationsCassettes[operationNo].length > scope.$index + scope.row.rowSpan"
                                    :class="{'rgv-num-line': operationsCassettes[operationNo].length - scope.$index != 1, 'rgv-num-line-green': isNumLineGreen(scope.$index + 1, scope.row.status, 'operationsCassettes', operationNo)}"
                                    :style="{height: scope.row.rowSpan > 1 ? 11*(scope.row.rowSpan+(scope.row.rowSpan/1.5))+'px' : '11px'}">
                                </div>
                                <div 
                                    :class="{'rgv-num-line-hidden': operationsCassettes[operationNo].length - scope.$index == 1}"
                                    :style="{height: scope.row.rowSpan > 1 && operationsCassettes[operationNo].length == scope.$index + scope.row.rowSpan ? 11*(scope.row.rowSpan+(scope.row.rowSpan/1.5))+'px' : '0'}">
                                </div>
                                <!-- НИЖНЯЯ ПОЛОСА ПОД ШАРИКОМ -->
                                
                                <img src="@/assets/img/lkz_ico_20.svg" alt="v" class="finisfed-iconnnnnnnn" v-if="isFinished && scope.$index == 0">
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('researchDialog.operationTh1')" width="150">
                            <template slot-scope="scope">
                                {{ scope.row.executionDate | dateFormat(true)}}
                                
                                <!-- INFO-IMP: DEBUG -->
                                <!-- 
                                <br> rowSpan={{scope.row.rowSpan}} {{scope.$index + scope.row.rowSpan}}
                                <br> {{scope.$index}}
                                <br> {{operationsCassettes[operationNo].length}}
                                <br> {{operationsCassettes[operationNo].length - scope.$index}}
                                <br> {{!scope.row.rowSpan}} {{operationsCassettes[operationNo].length > scope.$index + scope.row.rowSpan}}
                                <br> {{operationsCassettes[operationNo].length - scope.$index == 1}} 
                                -->
                                <!-- INFO-IMP: DEBUG -->
                                <!--
                                <br> {{!scope.row.rowSpan || operationsCassettes[operationNo].length > scope.$index + scope.row.rowSpan}}
                                <br> {{operationsCassettes[operationNo].length - scope.$index == 1}} {{scope.row.rowSpan > 1 && operationsCassettes[operationNo].length == scope.$index + scope.row.rowSpan ? 11*(scope.row.rowSpan+(scope.row.rowSpan/1.5))+'px' : '0'}} 
                                -->
                                
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('researchDialog.operationTh2')" width="150">
                            <template slot-scope="scope">
                                <div class="rgv-table-icon">
                                    <img src="@/assets/img/lkz_ico_26.svg" alt="v" class="rgv-status-icon" v-if="scope.row.status==1">
                                    <!-- <img src="@/assets/img/lkz_ico_16.svg" alt="x" class="rgv-status-icon" v-if="scope.row.status==2" style="width: 12px; height: 12px; margin: 0 16px 0 4px;"> -->
                                    <img src="@/assets/img/lkz_ico_72.svg" alt="!" class="rgv-status-icon" v-if="scope.row.status==2 || scope.row.status==3 || scope.row.status==4 || scope.row.status==5">
                                    <div class="rgv-status-icon" v-if="scope.row.status == 0"></div>
                                </div>
                                <div class="rgv-table-text">{{ $t('researchDialog.operationStatuses')[scope.row.status]}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="count" :label="$t('researchDialog.operationTh9')" width="210"></el-table-column>
                        <el-table-column prop="signature" :label="$t('researchDialog.operationTh4')" width="210"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="no-data-stub" v-if="cassettes.length == 0 && sortedResearchData.length == 0">{{$t('researchDialog.noData')}}</div>
    </div>
</template>


<script>
import {SERVER_BASE_URL, SERVER_ATTACH_SEARCH_URL, TOKEN_LOCALSTORAGE_NAME} from '../../../config/constants';

export default {
  //height -- если не передавать, то будет 100%, иначе будет установлено на основании расчета. так сделано, что бы было 
  //    одинаково с ResearchInfoProbe, там либо передаётся, либо 100%, а тут если передано, то будет перерасчитано...
  props: ['researchData', 'grayCell', 'height', 'tableBgStyle', 'files', 'isFinished', 'cassettes'],
  data() {
    return {
        token: window.localStorage.getItem(TOKEN_LOCALSTORAGE_NAME),
        sortedResearchData: [],
        operationsPropant: {},   // операции по Пропанту, сгруппированные по номеру операции
        operationsCassettes: {}, // операции по Кассетам, сгруппированные по номеру операции
        operationsPropantKeys: [],   // операции по Пропанту, сгруппированные по номеру операции
        operationsCassettesKeys: [], // операции по Кассетам, сгруппированные по номеру операции
        isRebuildFinished: false,
        tableHeight: '100%'
    }
  },
  methods: {
    init() {
        this.sortData();
        this.defineOperationsNums();
        this.calculateTablesWidth();
        this.reduildData();
    },
    sortData() {
        //INFO: IMPORTANT: в первом случае с researchData сортируем новый объект, а не исходный, тк в другом месте сортировка должна 
        //  быть по возрастанию, и если сортировать researchData то он отсортируется и там тоже и будет отображаться не правильно!
        //  в случае с cassettes -- они больше нигде не используются
        Object.assign(this.sortedResearchData, this.researchData);
        this.sortedResearchData.sort((a, b) => {
            return b.no - a.no;
        });
        this.cassettes.sort((a, b) => {
            return b.no - a.no;
        });
    },
    defineOperationsNums() {
        this.defineOperationNums(this.sortedResearchData, 'operationsPropant');
        this.defineOperationNums(this.cassettes, 'operationsCassettes');
    },
    defineOperationNums(data, objectName) {
        data.forEach(el => {
            const temp = el['fieldOperationNo'];
            if(!this[objectName][temp]) {
                 this[objectName][temp] = [];
            } 
            this[objectName][temp].push(el);
        });
        this[objectName+'Keys'] = Object.keys(this[objectName]);
        this[objectName+'Keys'].sort();
    },
    calculateTablesWidth() {
        // if(!this.height) {
        //     this.tableHeight = '100%';
        // } else {
        //     if(this.cassettes.length == 0 || this.sortedResearchData.length == 0) {
        //         this.tableHeight = 493;
        //     }
        // }
    },
    reduildData() {
        // this.sortedResearchData.forEach((el, index) => {
        //     this.setDataForEl(el, index);
        // })
        this.operationsPropantKeys.forEach(operationNo => {
            this.calcRowspans(this.operationsPropant[operationNo]);
        });
        this.operationsCassettesKeys.forEach(operationNo => {
            this.calcRowspans(this.operationsCassettes[operationNo]);
        });
        // this.calcRowspans(this.sortedResearchData);
        // this.calcRowspans(this.cassettes);
        this.isRebuildFinished = true;
    },
    // setDataForEl(el, index) {
    //     if(!el.stageNo) {
    //         if(this.sortedResearchData[index + 1] && this.sortedResearchData[index + 1].stageNo) {
    //             el.stageNo = this.sortedResearchData[index + 1].stageNo;
    //             this.sortedResearchData[index + 1].isDeleted = true;
    //         } else {
    //             if(index < this.sortedResearchData.length) {
    //                 this.setDataForEl(el, index + 1)
    //             }
    //         }
    //     }
    // },
    isNumLineGreen(index, status, dataName, operationNo) {
        if(this[dataName] && this[dataName][operationNo]) {
            let nextRow = this[dataName][operationNo][index];
            if(nextRow && nextRow.status) {
                if(this.isStatusSuccess(nextRow.status) && this.isStatusSuccess(status)) {
                    return true;
                }
            }
        }
        return false;
    },
    isStatusSuccess(status) {
        return status == 1 || status == 2 || status == 4 || status == 5
    },
    buildCellStyle(cell) {
        let cs  = {};
        if(this.grayCell) {
            cs['background-color'] = '#EFF4F6';
        }
        if(cell.columnIndex == 0) {
            cs['border-bottom'] = 'none';
        }
        // if(cell.columnIndex == 1 && !cell.row.stageNo) {
        //     cs['border-bottom'] = 'none';
        // }
        return cs;
    },
    generateActDownloadUrls(row) {
        let f = [];
        this.files.forEach(el => {
            //stageNo -- in old version 
            if(el.name && row.no == el.name.split('-')[1]) {
                f.push(SERVER_BASE_URL + SERVER_ATTACH_SEARCH_URL + this.token + '&dwid='+el.dwid);
            }
        })
        return f;
    },
    calcRowspans(dataArray) {
        let rdLen = dataArray.length;
        for(let ind = 0; ind < rdLen; ind++) {
            let el = dataArray[ind];
            let rowSpan = 1;
            if(el.stageNo) {
                //перебираем все последующие элементы в массиве с данными, что бы проверить, будут ли совпадения у следующих 
                //  строчек по otchet_id с текущей. если совпадения есть, то строки в таблице объединять, поэтому rowSpan++
                //  тк объединять может надо будет не только две соседнии, но и больше, поэтому перебираем все последующие
                //  в цикле. как только определили, что совпадений нет -- выходим из цикла поиска совпадений: else { break; }
                for(let i = ind+1; i<rdLen; i++) {
                    if(el.stageNo && el.stageNo == dataArray[i].stageNo) {
                        rowSpan++;
                        el.executionDate = dataArray[i].executionDate;
                        ind++;
                        //если нашли совпадения по otchet_id у следующих строк ставим rowSpan=0, что бы её вообще выкинуть из таблицы.
                        dataArray[i].rowSpan=0;
                    } else {
                        break;
                    }
                }
                if(rowSpan > 1) {
                    //если нашли совпадения, то у текущей строки устанавливаем сколько строк надо объединить в таблице
                    el.rowSpan=rowSpan;
                }
            }
        }
    },
    /* eslint-disable-next-line no-unused-vars */
    rowSpanMethod({ row, column, rowIndex, columnIndex }) {
        if(columnIndex == 0 || columnIndex == 1) {
            if(row.rowSpan > 0) {
                return {rowspan: row.rowSpan, colspan: 1}
            }
            if(row.rowSpan === 0) {
                return {rowspan: 0, colspan: 0}
            }
        }
    }
  },
  mounted() {
      this.init();
  }
}
</script>

<style src="../ResearchInfoExt.css" scoped></style>
<style scoped>
a.zz {
    border-bottom: none;
    margin-top: 10px;
    display: inline-block;
}
.dailog-subheader {
    font-size: 22px;
    margin-left: 30px;
}
.no-data-stub {
    height: 560px;
    width: 880px;
    text-align: center;
    line-height: 520px;
}
.operation-tables-in-dialog {
    height: 550px; 
    overflow-y: auto; 
    overflow-x: hidden;
}
</style>