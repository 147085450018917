<template>
    <div class="research-grafical-dialog">
        <el-dialog
            :visible.sync="isShowDialog"
            :width="researchDialogParams && researchDialogParams.rt == 'operation' ? '920px' : '990px'"
            destroy-on-close
            append-to-body
            :before-close="handleClose"
            :title="dialogTitle"
            >

            <div :style="{'width': researchDialogParams && researchDialogParams.rt == 'operation' ? '860px' : '920px', 
                         'height': researchDialogParams && researchDialogParams.rt == 'operation' ? '100%' : '550px'}">

            <ResearchInfoOperation 
                v-if="researchDialogParams && researchDialogParams.rt == 'operation'" 
                :researchData="researchData" :cassettes="cassettes"
                :tableBgStyle="'nohover'" :isFinished="researchDialogParams.isFinished"
                :height="true"></ResearchInfoOperation>
            <ResearchInfoProbe 
                v-if="researchDialogParams && researchDialogParams.rt != 'operation'"
                :researchData="researchData" 
                :tableBgStyle="'nohover'"  :isFinished="researchDialogParams.isFinished" :isFinalReport="finalReports[researchDialogParams.id]"
                :height="550"></ResearchInfoProbe>
            </div>
        </el-dialog>
    </div>
</template>


<script>
import * as constants from '../../../config/constants';
import ResearchInfoOperation from './ResearchInfoOperation.vue';
import ResearchInfoProbe from './ResearchInfoProbe.vue'

export default {
  props: ['researchDialogParams', 'finalReports'],
  computed: {
    isShowDialog () {
        this.showDialog()
        return this.researchDialogParams.id != null;
    }
    //   ...mapGetters(['currentUser'])
    },
  data() {
      return {
          researchData: [],
          cassettes: [],
          dialogTitle: '', 
          isLoading: true,
          token: window.localStorage.getItem(constants.TOKEN_LOCALSTORAGE_NAME)
      }
  },
  methods: {
    showDialog() {
        if(this.researchDialogParams.rt == 'operation') {
            this.dialogTitle = this.$t('researchDialog.operationTitle');
        } else if(this.researchDialogParams.rt == 'probe' || this.researchDialogParams.rt == 'labtest') {
            this.dialogTitle = this.$t('researchDialog.probeTitle');
        }
        if(this.researchDialogParams && Array.isArray(this.researchDialogParams.data)) {
            this.researchData = this.researchDialogParams.data;
        } else {
            this.researchData = [];
        }
        if(this.researchDialogParams && Array.isArray(this.researchDialogParams.cassettes)) {
            this.cassettes = this.researchDialogParams.cassettes;
        } else {
            this.cassettes = [];
        }
    },
    handleClose() {
        this.$emit('event-research-grafical-dialog-close');
    },
  },
  components: {
      ResearchInfoOperation, ResearchInfoProbe
  }
}
</script>

<style scoped>
a {
    color: #606266;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    text-decoration: none;
    border-bottom: 1px dashed #24c967;
}
</style>

<style>
.el-dialog__title {
    height: 16px;
    margin-left: 30px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #25272f;
}
/* .el-dialog {
    margin-top: 10vh!important;
} */
</style>