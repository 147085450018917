<template>
    <div class="research-info-panel">
      <div class="research-info-header">
          <div class="go-back-button clickable go-back-button-wrapper" @click="goBack"></div>
        <div class="research-row-wrapper">
          <ResearchRow class="research-row-info-page" style="display: inline-block"
              :gf="gf" :r="r" :finalReports="finalReports" 
              :isShowTotal="true" :rowType="'partial'"
              @event-research-grafical-set-favorite="setFavoriteSelectedId"></ResearchRow>
        </div>

        <div class="client-logo logo-rf">
          <div class="company-logo" :style="{'background-image': 'url('+logo+')'}"></div>
        </div>
      </div>

      <!-- {{r.id}} -->

      <div class="research-full-info-wrapper">
        <perfect-scrollbar :options="{minScrollbarLength: 122, suppressScrollX: true}">

          <div class="geofield-group-title">
              <img src="@/assets/img/lkz_ico_14.svg" alt="-" class="rf-group-icon clickable" v-show="!isTotalRepHidden" @click="isTotalRepHidden = true" :class="{ disabled: isShowSurveyCard }">
              <img src="@/assets/img/lkz_ico_13.svg" alt="+" class="rf-group-icon clickable" v-show="isTotalRepHidden" @click="isTotalRepHidden = false" :class="{ disabled: isShowSurveyCard }">
              <div class="rf-group-title rf-group-title2" :class="{ disabled: isShowSurveyCard }">{{$t('researchInfoForm.finalReport')}}</div>

              <div class="rf-survey-button clickable" @click="isShowSurveyCard = true" :class="{ disabled: isShowSurveyCard }">
                <img src="@/assets/img/lkz_ico_77.svg" alt="Q" class="survey-icon"> 
                <div class="survey-text">{{$t('survey.tabLabel')}}</div>
              </div>
          </div>

          <!--INFO: ТАБЫ -->
          <div v-show="isTotalRepHidden == false">
            <div class="research-filter-info-p" v-show="chartData && chartData.length > 0">
              <div class="research-filter-block research-filter-i1 " v-bind:class="{'research-filter-active': totalRepFilterActive==1}" @click="totalRepFilterActive = 1">{{$t('researchInfoForm.f1')}}</div>
              <div class="research-filter-block research-filter-i2" v-bind:class="{'research-filter-active': totalRepFilterActive==2}" @click="totalRepFilterActive = 2">{{$t('researchInfoForm.f2')}}</div>
              <div class="research-filter-block research-filter-i3" v-bind:class="{'research-filter-active': totalRepFilterActive==3}" @click="totalRepFilterActive = 3">{{$t('researchInfoForm.f3')}}</div>
            </div>

            <el-row v-show="chartData && chartData.length > 0">
              <ResearchInfoChartOne :gsId="r.id" :rows="chartData" v-show="totalRepFilterActive == 1" v-loading="isLoading"></ResearchInfoChartOne>
              <ResearchInfoChartOneTwo :gsId="r.id" :rows="chartData" v-show="totalRepFilterActive == 2" v-loading="isLoading" :maxByRows="maxByRows"></ResearchInfoChartOneTwo>
              <ResearchInfoChartOneThree :gsId="r.id" :rows="chartData" v-show="totalRepFilterActive == 3" v-loading="isLoading"></ResearchInfoChartOneThree>
            </el-row>
            <el-row v-show="!chartData || chartData.length == 0">
              <div class="chart-card-empty">
                <div class="chart-card-empty-icon"></div>
                <el-row>
                  <div class="chart-card-empty-text">{{$t('researchInfoForm.conclusionsEmpty')}}</div>
                </el-row>
              </div>
            </el-row>
          </div>

          <!-- INFO: Полевая операция -->
          <div class="geofield-group-title w100">
              <img src="@/assets/img/lkz_ico_14.svg" alt="-" class="rf-group-icon clickable" v-show="!isOperRepHidden" @click="isOperRepHidden = true" >
              <img src="@/assets/img/lkz_ico_13.svg" alt="+" class="rf-group-icon clickable" v-show="isOperRepHidden" @click="isOperRepHidden = false" >
              <div class="rf-group-title rf-group-title2" >{{$t('researchDialog.operationTitle')}}</div>
          </div>
          <ResearchInfoOperation 
            v-show="!isOperRepHidden"
            :researchData="r.documentsupervising_stages" :cassettes="r.documentsupervising_cassettes" 
            :grayCell="true" :tableBgStyle="'nohover-gray'" :files="r.files2"></ResearchInfoOperation>

          <el-row>
            <div class="reesters" v-show="!isOperRepHidden">
              <div v-for="f in r.files" :key="f.name">
                <el-row>
                  <div class="reestr-row">
                    <div class="rgv-table-icon">
                      <img src="@/assets/img/lkz_ico_4.svg" alt="o" class="rgv-status-icon">
                    </div>
                    <a :href="generateReestrDownloadUrl(f)" target="_blank"><span class="reestr-bold clickable link-text">{{$t('researchInfoForm.str6')}}</span></a>
                    <span class="reestr-grey">{{f.filename}} </span>
                    <span class="reestr-grey">{{f.docdate | dateFormat(true)}}</span>
                  </div>
                </el-row>
              </div>
            </div>
          </el-row>
          <!-- Полевая операция -->

          <!-- INFO: Отбор проб и лабораторные исследования -->
          <div class="geofield-group-title">
              <img src="@/assets/img/lkz_ico_14.svg" alt="-" class="rf-group-icon clickable" v-show="!isProbeRepHidden" @click="isProbeRepHidden = true" >
              <img src="@/assets/img/lkz_ico_13.svg" alt="+" class="rf-group-icon clickable" v-show="isProbeRepHidden" @click="isProbeRepHidden = false" >
              <div class="rf-group-title rf-group-title2" >{{$t('researchDialog.probeTitle')}}</div>
          </div>
          <ResearchInfoProbe :researchData="r.packages" v-show="!isProbeRepHidden" :isFinalReport="finalReports[r.id]"
            :grayCell="true" :tableBgStyle="'nohover-gray'"></ResearchInfoProbe>
          <!-- INFO: Отбор проб и лабораторные исследования -->

          <ResearchInfoConclusions :chartData="chartData"></ResearchInfoConclusions>
  
        </perfect-scrollbar>
      </div>

      <SurveyNewCardRegularWrapper :isShowSurveyCard="isShowSurveyCard" :gsId="r.id"
        @event-survey-close-quater="isShowSurveyCard = false">
      </SurveyNewCardRegularWrapper>

    </div>
</template>

<script>
import moment from 'moment';
import {SERVER_BASE_URL, SERVER_ATTACH_SEARCH_URL, TOKEN_LOCALSTORAGE_NAME} from '../../config/constants';
import serverResearch from "../../api/server-research";
import ResearchRow from './componets/ResearchRow.vue';
import ResearchInfoChartOne from './ResearchInfoChartOne.vue';
import ResearchInfoChartOneTwo from './ResearchInfoChartOneTwo.vue';
import ResearchInfoChartOneThree from './ResearchInfoChartOneThree.vue';
import ResearchInfoOperation from './componets/ResearchInfoOperation.vue';
import ResearchInfoProbe from './componets/ResearchInfoProbe.vue';
import ResearchInfoConclusions from './ResearchInfoConclusions.vue';
import SurveyNewCardRegularWrapper from '../Survey/SurveyNewCardRegularWrapper.vue';

// import * as chConsts from './ChartHelpers/ChartConstants';
// import chHelpers from './ChartHelpers/ChartHelpers';
import lmCalculator from './ChartHelpers/LiquidAndMaxCalculator';

export default {
  props: ['gf', 'r', 'finalReports', 'isShowTotal', 'logo'],
  data() {
    return {
      isLoading: true,
      isTotalRepHidden: false, //переменная для сворачивания первой группы граффиков (3 вкладки)
      isOperRepHidden: false, //отображает раздел "Полевая операция"
      isProbeRepHidden: false, //отображает раздел "Отбор проб и лабораторные исследования"
      totalRepFilterActive: 1, //выбранный таб на первой группе вкладок
      chartData: [],
      maxByRows: {}, //максимум по всем строкам 
      token: window.localStorage.getItem(TOKEN_LOCALSTORAGE_NAME),
      isShowSurveyCard: false,
    }
  },
  methods: {
    init() {
      this.$store.dispatch('stopRetrievingNewSurveyCount');
      this.isLoading = true;
      serverResearch.documentreports(this.r.id, data => {
        if(data && data[0]) {
          this.calculateLiquidAndMaxs(data);
          this.chartData = data;
        }
        this.isLoading = false;
        this.sortFilesArray();
      });
    },
    sortFilesArray() {
      if(this.r.files) {
          this.r.files.sort((a, b) => {
            let aa = moment(a.docdate).format('YYYYMMDDHHmm');
            let bb = moment(b.docdate).format('YYYYMMDDHHmm');
            if(aa > bb) {
              return 1
            } 
            if(aa < bb) {
              return -1
            } 
            //если не отсортировали по дате, сортируем по имени
            if(a.filename > b.filename) {
              return 1
            } 
            if(a.filename < b.filename) {
              return -1
            } 
            return 0;
          });
        }
    },
    goBack() {
      this.$store.dispatch('retrieveNewSurveyCount');
      this.$emit('event-research-hide-full-info');
      this.$destroy();
    },
    generateReestrDownloadUrl(row) {
      let url = SERVER_BASE_URL + SERVER_ATTACH_SEARCH_URL + this.token + '&dwid='+row.dwid;
      return url;
    },
    setFavoriteSelectedId(gsId, fvId) {
      this.$emit('event-research-grafical-set-favorite2', gsId, fvId);
    },
    calculateLiquidAndMaxs(rows) {
      let maxs = {}; //максимум по всем строкам 
      lmCalculator.initMaxsStrecture(maxs); //максимум по всем строкам 
      rows.forEach(row => {
        if(row && row.modeee) {
          let drMode = row.modeee;
          if(drMode && drMode.documentReport_Markers && drMode.no == 0) {
            drMode.documentReport_Markers.forEach(el => {
              lmCalculator.calculateMarker(el, drMode);
            });
            lmCalculator.roundMaxs(drMode);
            lmCalculator.setMaxByRowForAllRows(maxs, drMode); //максимум по всем строкам 
          }
        }
      });
      this.maxByRows = maxs; //максимум по всем строкам 
    },
  },
  components: {
    ResearchRow, 
    ResearchInfoChartOne, ResearchInfoChartOneTwo, ResearchInfoChartOneThree,
    ResearchInfoOperation, ResearchInfoProbe, ResearchInfoConclusions,
    SurveyNewCardRegularWrapper
  },
  mounted() {
    this.init();
  }
};
</script>

<style src="./Research.css" scoped></style>
<style src="./ResearchInfo.css" scoped></style>

<style scoped>
.rf-survey-button {
  float: right;
  width: 274px;
  height: 54px;
  color: #6377fa;
  border-radius: 30px;
  box-shadow: 0px 1px 5.8px 2.2px rgba(95, 116, 245, 0.8);
  background-color: #fff;
  margin: -40px 50px 0;
}

.survey-icon {
  float: left;
  width: 23px;
  height: 30px;
  margin: 12px 8px 0 43px;
}
.survey-text {
  float: left;
  margin: 18px 0;
}

.disabled {
    opacity: 0.2;
}
</style>