<template>
    <div class="chart-row chart-card-one chart-card-one-three">
  
      <el-row class="chart-headers-row">
        <el-col :span="10">
          <CompositionLegend 
            :checkedOptions="initialCompositionState"
            @event-research-legend-set-value="legendSelectFilter"></CompositionLegend>
        </el-col>
      </el-row>

      <el-row class="charts-panel-root" :gutter="20"> <!-- charts-panel-root -- для объвления переменных css -->
        <!-- INFO: карточки с графиками -->
        <div class="charts-panel">
          <div
            v-for="(row, key) in chartCards" :key="row.portOrig" 
            v-show="ports && ports[key] && ports[key].isSelected">
              <ChartCard :chartData="row" :port="key" :compositionState="compositionState" :cardTitle="$t('researchInfoForm.port') + ' ' + key"></ChartCard>
          </div>
        </div>
        
        <!-- INFO: карточка выбора интервалов(портов) -->
        <div class="chart-card intervals-card">
          <div class="header ports-header">{{$t('researchInfoForm.ports')}}</div>
          <div 
            v-for="port in ports" :key="port.portNum" 
            class="probepacket-select port-chb-select">
              <el-checkbox v-model="ports[port.portNum].isSelected">
                <span class="port-chb-label">{{$t('researchInfoForm.portShort')}} {{port.portNum}}</span>
              </el-checkbox>
          </div>
        </div> 
      </el-row>
      
    </div>
</template>

<script>
import chHelpers from './ChartHelpers/ChartHelpers';
import DataTransformer from './ChartHelpers/ChartOneThreeDataTransformer';
import CompositionLegend from './componets/CompositionLegend.vue';
import ChartCard from './componets/ChartCard.vue';

export default {
  props: ['gsId', 'rows'],
  data() {
    return {
      ports: {},
      chartCards: {}, //данные для отображения карточек с графиками
      compositionState: {}, //состояние выбрвнных чекбоксов на легенде
      initialCompositionState: [], //начальное состояние чекбоксов на легенде. расчитывается по данным и потом не должно меняться
    };
  },
  methods: {
    init() {
      const dataTransformer = new DataTransformer(this.rows);
      dataTransformer.transform();
      this.ports = dataTransformer.ports;
      this.chartCards = dataTransformer.chartCards;

      this.defineInitialCompositionState();
    },
    //по договоренности определяем какие чекбоксы будут выделены на легенде по набору данных для первой карточки
    defineInitialCompositionState() {
      const keys = Object.keys(this.chartCards);
      if(keys[0]) {
        this.initialCompositionState = chHelpers.defineCompositionLegendCheckedOptions(this.chartCards[keys[0]]);
      }
    },
    legendSelectFilter(compositionState) {
      this.compositionState = compositionState;
    },
  },
  components: {
    CompositionLegend, ChartCard
  },
  // mounted() {
  //   if(this.option && this.option.dataset) {
  //     this.option.dataset.source = [];
  //   }
  // },
  watch: {
    'rows': {
        handler: function (val, old) {
          if(val && val[0] && !old[0]) {
            this.init();
          } 
        }
    }
  }
};
</script>

<style src="./ResearchInfoChart.css" scoped></style>
<style scoped>
.chart-headers-row {
  margin: 10px 0 30px 55px;
}
.charts-panel-root {
  --card-height: 650px;
}
.charts-panel {
  min-width: 1550px;
  width: calc(100vw - 375px);
  min-height: var(--card-height);;
  float: left;
}
.intervals-card {
  width: 205px;
  height: var(--card-height);
  float: left;
}

.ports-header {
  width: 126px;
  margin: 30px auto;
}

.port-chb-select {
  margin-left: 28px;
}
.port-chb-label  {
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #31343d;
}
</style>