<template>
  <div v-if="currentUser">
    
     <!-- style="border: 1px solid" -->
    <div class="subpage-header" >
      <div class="page-header">{{$t('researchForm.label')}}</div>

        <div class="controlls">
          <div class="research-filter">
            <div class="research-filter-block research-filter-all bold" v-bind:class="{'research-filter-active': filterActive==filfers.all}"
              @click="setFilter(filfers.all)">{{$t('researchForm.filterAll')}} <div class="research-filter-counter">{{researches.length}}</div></div>
            <div class="research-filter-block research-filter-cur bold" v-bind:class="{'research-filter-active': filterActive==filfers.current}"
              @click="setFilter(filfers.current)">{{$t('researchForm.filterCurrent')}} <div class="research-filter-counter">{{researches.length - finishedCount}}</div></div>
            <div class="research-filter-block research-filter-fin bold" v-bind:class="{'research-filter-active': filterActive==filfers.finished}"
              @click="setFilter(filfers.finished)">{{$t('researchForm.filterFinished')}} <div class="research-filter-counter">{{finishedCount}}</div></div>
          </div>

          <div class="search-group">
            <input v-model="gsNameFilter" class="search-input" :placeholder="$t('search')" @keyup.enter="buildGfTree">
            <img src="@/assets/img/lkz_ico_10.svg" alt="o" class="search-icon1 clickable" v-show="!gsNameFilter" @click="buildGfTree">
            <img src="@/assets/img/lkz_ico_34.svg" alt="x" class="search-icon1 clickable" v-show="gsNameFilter" @click="gsNameFilter = null; buildGfTree()">
          </div>

          <!-- <img :src="[filterByFavorites ? '@/assets/img/lkz_ico_8.svg' : '@/assets/img/lkz_ico_11.svg']" alt="fav" 
            :model="filterByFavorites" @click="filterByGs"
            class="search-icon search-icon-margin clickable"> -->
          <div v-show="isFavoriteFilterCanBeActivated" 
            :class="[filterByFavorites ? 'rf-star-selected-bg' : 'rf-star-bg-header']" 
            class="search-icon2 search-icon-margin clickable"
            @click="setFilterByFavorites">
          </div>
          <div v-show="!isFavoriteFilterCanBeActivated" class="search-icon2 search-icon-margin rf-star-bg-header disabled"></div>
          
          <!-- <img src="@/assets/img/lkz_ico_12.svg" alt="msg" class="search-icon3 search-icon-margin disabled"> -->
          <survey-notifier marginRight="400px" class="search-icon3 search-icon-margin"
            @event-survey-show-quater="isShowSurveyCard = true"></survey-notifier>

          <div class="client-logo">
            <div class="company-logo" :style="{'background-image': 'url('+logo+')'}"></div>
          </div>
        </div>
     </div>
    
    <!-- style="border: 1px solid" -->
    <div class="page-content-with-command" v-show="!isShowNotFoundStub">
      <div class="research-headers">
        <div class="research-sorted-header" style="margin-right: 10px;">
          <img src="@/assets/img/lkz_ico_78.svg" alt="-" class="rf-group-icon clickable" @click="changeCollapsedAll" v-show="!isCollapsedAll">
          <img src="@/assets/img/lkz_ico_79.svg" alt="+" class="rf-group-icon clickable" @click="changeCollapsedAll" v-show="isCollapsedAll">
        </div>
        <div style="width: 105px; margin-left: 10px;" class="research-sorted-header clickable bold" @click="sortRows('sort_gs_title')">{{$t('researchForm.th1')}}
          <img src="@/assets/img/lkz_ico_1.svg" alt="" class="sort-icon" v-show="sortingField == 'sort_gs_title' && sortingAsc">
          <img src="@/assets/img/lkz_ico_2.svg" alt="" class="sort-icon" v-show="sortingField == 'sort_gs_title' && !sortingAsc">
          <img src="@/assets/img/lkz_ico_9.svg" alt="" class="sort-icon" v-show="sortingField != 'sort_gs_title'">
        </div>
        <div style="width: 70px;" class="research-sorted-header clickable bold" @click="sortRows('sort_gsg_title')">{{$t('researchForm.th2')}}
          <img src="@/assets/img/lkz_ico_1.svg" alt="" class="sort-icon" v-show="sortingField == 'sort_gsg_title' && sortingAsc">
          <img src="@/assets/img/lkz_ico_2.svg" alt="" class="sort-icon" v-show="sortingField == 'sort_gsg_title' && !sortingAsc">
          <img src="@/assets/img/lkz_ico_9.svg" alt="" class="sort-icon" v-show="sortingField != 'sort_gsg_title'">
        </div>
        <div style="width: 180px;" class="research-sorted-header clickable bold" @click="sortRows('solution_type')">
          <div style="float: left; width: 155px">{{$t('researchForm.th3')}}</div>
          <div  style="float: left">
            <img src="@/assets/img/lkz_ico_1.svg" alt="" class="sort-icon" v-show="sortingField == 'solution_type' && sortingAsc">
            <img src="@/assets/img/lkz_ico_2.svg" alt="" class="sort-icon" v-show="sortingField == 'solution_type' && !sortingAsc">
            <img src="@/assets/img/lkz_ico_9.svg" alt="" class="sort-icon" v-show="sortingField != 'solution_type'">
          </div>
        </div>

        <!-- TODO: заглушка, что бы подвинуть элементы пока скрыт элемент отображения процента выполнения исследования -->
        <!-- <div style="width: 30px; height: 20px;"  class="research-sorted-header"></div> -->

        <div style="width: 130px;" class="research-sorted-header bold clickable" @click="sortRows('investigationstatus_id')">{{$t('researchForm.th8')}}
          <img src="@/assets/img/lkz_ico_1.svg" alt="" class="sort-icon" v-show="sortingField == 'investigationstatus_id' && sortingAsc">
          <img src="@/assets/img/lkz_ico_2.svg" alt="" class="sort-icon" v-show="sortingField == 'investigationstatus_id' && !sortingAsc">
          <img src="@/assets/img/lkz_ico_9.svg" alt="" class="sort-icon" v-show="sortingField != 'investigationstatus_id'">
        </div>

        <div style="width: 315px;" class="research-sorted-header bold">{{$t('researchForm.th4')}}</div>
        <div style="width: 310px;" class="research-sorted-header bold">{{$t('researchForm.th5')}}</div>
        <div style="width: 250px;" class="research-sorted-header bold">{{$t('researchForm.th6')}}</div>

        <!-- TODO: IMPORTANT: при отображении процента выполнения удалить заглушку заполнитель выше! -->
        <!-- <div style="width: 85px;" class="research-sorted-header clickable bold" @click="sortRows('total')">{{$t('researchForm.th7')}}
          <img src="@/assets/img/lkz_ico_1.svg" alt="" class="sort-icon" v-show="sortingField == 'total' && sortingAsc">
          <img src="@/assets/img/lkz_ico_2.svg" alt="" class="sort-icon" v-show="sortingField == 'total' && !sortingAsc">
          <img src="@/assets/img/lkz_ico_9.svg" alt="" class="sort-icon" v-show="sortingField != 'total'">
        </div> -->
      </div>

      <div  class="researches" v-show="isResearchInfoVisible == false">
        <perfect-scrollbar :options="{minScrollbarLength: 122}">
          
          <div 
            v-for="(subsidiary, sTitle) in researchesTree" :key="sTitle">
            <div class="geofield-group-title" v-show="subsidiary.isNotEmpty">
              <img src="@/assets/img/lkz_ico_14.svg" alt="-" class="rf-group-icon clickable" @click="changeCollapsed(subsidiary)" v-show="!subsidiary.isCollapsed">
              <img src="@/assets/img/lkz_ico_13.svg" alt="+" class="rf-group-icon clickable" @click="changeCollapsed(subsidiary)" v-show="subsidiary.isCollapsed">
                <div class="rf-group-title">{{sTitle}}</div>
                
                <div 
                  v-for="(gf, gfTitle) in subsidiary" :key="gfTitle">
                  <div class="geofield-group-title geofield-group-title-margin" v-show="gf.isNotEmpty && !subsidiary.isCollapsed">
                    <img src="@/assets/img/lkz_ico_14.svg" alt="-" class="rf-group-icon clickable" @click="changeCollapsed(gf)" v-show="!gf.isCollapsed">
                    <img src="@/assets/img/lkz_ico_13.svg" alt="+" class="rf-group-icon clickable" @click="changeCollapsed(gf)" v-show="gf.isCollapsed">
                    <div class="rf-group-title">{{gfTitle}} <sup v-if="gf.geosplits" class="fg-sup">{{gf.geosplits.length}}</sup></div>
                  </div>

                  <div 
                    v-for="r in gf.geosplits" :key="r.id" class="geofield-group">
                    <!-- @event-research-grafical-view-total="setResearchGraficalViewTotal" -->
                    <research-row v-show="r.gs_title && !subsidiary.isCollapsed && !gf.isCollapsed"
                      :r="r" :finalReports="finalReports" 
                      :isShowTotal="isShowTotal"
                      :researchDialogActive="researchDialogActive" 
                      :researchDialogActiveType="researchDialogActiveType"
                      @event-research-show-full-info="showResearchInfo"
                      @event-research-grafical-show-dialog="showResearchDialog"
                      @event-research-grafical-set-favorite="setFavoriteSelectedId"
                    ></research-row>
                  </div>
                </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>

    <div v-show="isShowNotFoundStub" class="gs-not-found-stub">
      <img src="@/assets/img/lkz_ico_89.svg" alt="-" class="gs-not-found-stub-icon">
      <div class="gs-not-found-stub-text">{{$t('messages.gsNotFound')}}</div>
    </div>

    <command-user></command-user>
    <research-dialog :researchDialogParams="researchDialogParams" :finalReports="finalReports"
      @event-research-grafical-dialog-close="researchDialogParams = {}; researchDialogActive = 0; researchDialogActiveType = '';"></research-dialog>
    
    <div v-if="isHideInfoPage != 'true'">
      <div v-if="isResearchInfoVisible">
        <research-info :r="researchInfoFor" :finalReports="finalReports" 
          :isShowTotal="isShowTotal" :logo="logo"
          @event-research-hide-full-info="isResearchInfoVisible = false"
          @event-research-grafical-set-favorite2="setFavoriteSelectedId"></research-info>
      </div>
    </div>

    <SyrveyNewCardQuaterWrapper :isShowSurveyCard="isShowSurveyCard" :logo="logo"
      @event-survey-close-quater="isShowSurveyCard = false"></SyrveyNewCardQuaterWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utilites from '../../lib/utilites';
// import * as constants from '../config/constants';
import serverResearch from "../../api/server-research";
import CommandUser from "@/components/CommandUsers.vue";
import ResearchDialog from "./componets/ResearchDialog.vue";
import ResearchRow from "./componets/ResearchRow.vue";
import ResearchInfo from "./ResearchInfo.vue";
import LoadingMsg from "./componets/LoadingMsg";
import SurveyNotifier from "../Survey/SurveyNotifier.vue";
import SyrveyNewCardQuaterWrapper from "../Survey/SurveyNewCardQuaterWrapper.vue";

export default {
    data() {
        return {
          researches: [], //исследования в оригинальном виде как прилетели с сервера
          researchesTree: {},
          gsNameFilter: null,
          // totals: {}, //Объект для подсчета процента выполнения по строке.
          finalReports: {},
          calculatedTotals: 0,
          isCalculatedTotals: false, //Для скольки строк посчитали финальный % завершения исследования. если не использовать, то глючит отображение.
          filfers: {
            all: 1,
            current: 2,
            finished: 3
          },
          filterActive: 1,
          filterByFavorites: false,
          logo: '',
          isLoading: false,
          researchDialogParams: {},
          researchDialogActive: 0,
          researchDialogActiveType: '',
          sortingField: '', //устанавливается в методе retrieveResearches
          sortingAsc: true,
          isDataRequested: false,
          isResearchInfoVisible: false,
          researchInfoFor: null,
          isFavoriteFilterCanBeActivated: false,
          isHideInfoPage: process.env.VUE_APP_HIDE_RES_INFO, //скрывать страницу с информацией об исследовании на Проде. TODO: временный костыль, удалить когда будет доделана страница
          finishedCount: 0, //количество завершенных исследования для отображения количества в фильтре (все, текущие, завершенные)
          isCollapsedAll: false, //состояние переключателя Свернуть все группы
          isShowSurveyCard: false, //отображение катрочки для заполнения квартального опроса качества
        };
    },
    computed: {
      isShowTotal() {
        return this.researches.length > 0 && this.isCalculatedTotals
      },
      isShowNotFoundStub() {
        if(!this.gsNameFilter) return false;
        if(Object.keys(this.researchesTree) == 0 && this.gsNameFilter) return true;
        return false;
      },
      ...mapGetters(['currentUser'])
    }, 
    methods: {
        opened() {
          this.logo = utilites.companyConvertImage(this.currentUser);
          if(!this.isDataRequested) {
            this.isDataRequested = true;
            this.retrieveResearches();
          }
        },
        retrieveResearches() {
          //INFO-IMP: GUI блочит отрисовка, а не получение данных. Протестировано! Пока данные запрашиваются, 
          //          в GUI всё откликается, и даже buildGfTree не тормозит GUI, но всё подвисает, когда VUE
          //          пытается отрисовать всё, что мы получили и построили...
          this.isLoading = true;
          this.geofields = {};

          let lm = new LoadingMsg(this.$t('loading'));
          lm.show();

          serverResearch.retrieveResearches(data => {
            this.isDataRequested = false;
            this.isLoading = false;
            if(data && data[0]) {
              this.researches = data;
              this.buildGfTree();
              this.analizeResearches();
            }
            lm.close();
          });
        },
        buildGfTree() {
          let tree = {};
          this.researches.forEach(el => {
            if(this.isNeedAddRow(el)) {
              if(!tree[el.s_title]) {
                //Дочернее подразделение
                tree[el.s_title] = {};
                tree[el.s_title].isCollapsed = false;
              }
              if(!tree[el.s_title][el.gf_title]) {
                //Месторождение
                tree[el.s_title][el.gf_title] = {};
                tree[el.s_title][el.gf_title].isCollapsed = false;
                tree[el.s_title][el.gf_title].geosplits = []; //скважины складываем в массив, тк их надо сортировать по разным полям потом
              }
              if(!tree[el.s_title][el.gf_title][el.gs_title]) {
                //Скважины
                el.solution_type = this.buildSolutionType(el);
                tree[el.s_title][el.gf_title].geosplits.push(el);
                tree[el.s_title].isNotEmpty = true;
                tree[el.s_title][el.gf_title].isNotEmpty = true;
                tree[el.s_title][el.gf_title][el.gs_title] = true; //просто что бы исключить дубликаты
              }
            }
          });

          this.researchesTree = tree;
        },
        //проверка, надо ли добавлять скважину для отображения в соответствии с выбранными фильтрами. если
        //  выбран фильтр "избранные", то автоматом устанавливается фильтр "все", поэтому проверяем только
        //  на то, добавлена ли скважина в избранные или нет, и возвращаем результат сразу. остальные 
        //  фильтры (все, текущие, завершенные) проверяем последовательно
        isNeedAddRow(el) {
          if(this.filterByFavorites) {
            if(el.favorite_id != null) {
              return this.isRowMatchDyGsName(el);
            } else {
              return false;
            }
          }
          if(this.filterActive == this.filfers.current) {
            if( !this.isFinishedRow(el) ) {
              return this.isRowMatchDyGsName(el);
            } else {
              return false;
            }
          }
          if(this.filterActive == this.filfers.finished) {
            if( this.isFinishedRow(el) ) {
              return this.isRowMatchDyGsName(el);
            } else {
              return false;
            }
          }
          return this.isRowMatchDyGsName(el);
        },
        //если установлен фильт по имени скважины, то сравниваем имя скважины в текущей строке с фильтом...
        isRowMatchDyGsName(el) {
          if(this.gsNameFilter && el && el.gs_title) {
            if(this.gsNameFilter.startsWith('=')) {
              return el.gs_title.toUpperCase() == this.gsNameFilter.toUpperCase().replace('=', '');
            } else {
              return el.gs_title.toUpperCase().includes(this.gsNameFilter.toUpperCase());
            }
          }
          return true; 
        },
        buildSolutionType(row) {
          if(!row || !row.solutiontypes || row.solutiontypes.length == 0) return '';

          let name = '';
          row.solutiontypes.forEach(el => {
              if(el.solutionType && el.solutionType.isDeleted == false) {
                  if(name == '') {
                      name += el.solutionType.name
                  } else {
                      name += ', ' + el.solutionType.name
                  }
              }
          });
          return name;
        },
        changeCollapsed(node) {
          node.isCollapsed = !node.isCollapsed;
        },
        sortRows(field) {
          if(field == this.sortingField) {
            //INFO: если сортировка по тому же полю, что и до этого, то надо развернуть порядок следования эллементов.
            this.sortingAsc = !this.sortingAsc;
          } else {
            //INFO: если сортировка по новому полю, то устанавливаем это поле и делаем сортировку по возрастанию
            this.sortingField = field;
            this.sortingAsc = true;
          }

          for(let subsKey in this.researchesTree) {
            for(let gfKey in this.researchesTree[subsKey]) {
              let gf = this.researchesTree[subsKey][gfKey];
              if(gf.geosplits && gf.geosplits.length > 1) {
                this.sortGeosplits(gf.geosplits, field);
              }
            }
          }
        },
        sortGeosplits(geosplits) {
          geosplits.sort((aa, bb) => {
            //INFO: сравнение с null нормально не работает, поэтому заменяем null на пустую строку
            let a = aa[this.sortingField] ? aa[this.sortingField] : '';
            let b = bb[this.sortingField] ? bb[this.sortingField] : '';
            
            //INFO: тк % выполнения хранится в отдельном объекте, то сравниваем по % выполнения, но сортируем строки, тк id у них совпадают  
            // if(this.sortingField == 'total') {
            //   a = this.totals[aa.id].total;
            //   b = this.totals[bb.id].total;
            // }
            
            if(a < b) {
              return this.sortingAsc ? -1: 1;
            }
            if(a > b) {
              return this.sortingAsc ? 1: -1;
            }
            return 0;
          });
        },
        setFilter(filter) {
          this.filterActive = filter;
          if(filter != this.filfers.all) {
            this.filterByFavorites = false;
          }
          this.buildGfTree(); 
        },
        setFilterByFavorites() {
          this.filterByFavorites = !this.filterByFavorites;
          this.filterActive = this.filfers.all;
          this.buildGfTree();
        },
        analizeResearches() {
          this.isFavoriteFilterCanBeActivated = false;
          this.researches.forEach(el => {
            if(el.favorite_id != null) {
              this.isFavoriteFilterCanBeActivated = true;
            }
            if(this.isFinishedRow(el)) {
              this.finishedCount++;
            }
          });
        },
        setFinalReportId(repInfo) {
          this.finalReports[repInfo.id] = repInfo.repId;
        },
        isFinishedRow(row) {
          if(row && (row.investigationstatus_id == 4 || row.investigationstatus_id == 6)) {
            return true;
          }
          return false;
        },
        showResearchDialog(params) {
          this.researchDialogActive = params.id;
          this.researchDialogActiveType = params.rt;
          this.researchDialogParams = params; 
        }, 
        setFavoriteSelectedId(gsId, fvId) {
          let i = this.researches.findIndex(el => {
            return el.id == gsId
          });
          if(i >= 0) {
            this.researches[i].favorite_id = fvId;
          }
          // this.setIsFavoriteFilterActive();
        },
        showResearchInfo(params) {
          this.researchInfoFor = params;
          this.isResearchInfoVisible = true;
        },
        changeCollapsedAll() {
          this.isCollapsedAll = !this.isCollapsedAll;
          for(let subsidaryKey in this.researchesTree) {
            this.researchesTree[subsidaryKey].isCollapsed = this.isCollapsedAll;
          }
        },
        // initScrollBars() {
        //   // FOR perfect-scrollbar
        //   window.addEventListener('resize', this.updateScollBars);
        // },
        // updateScollBars() {
        //   // FOR perfect-scrollbar
        //   this.$refs.pfsResearches.update();
        // },

        //INFO-IMP: страрая версия расчета процента завершения исследований, нужна, что бы рисовать круговую диаграмму процента выполнения! пока не удалять
        //INFO: тут из контрола отрисовки исследований возвращаются посчитанные значения всего и завершено для каждого исследования
        // зная тип прсчета (полевые операции, отборы проб или лаб. исследования) и id строки с исследованиями, легко собрать
        // это всё в структуру для расчета процента завершения исследования
        // setResearchGraficalViewTotal(calc) {
        //   if(calc && calc.id) {
        //     if(!this.totals[calc.id]) {
        //       this.totals[calc.id] = {total: ''};
        //     }
        //     this.totals[calc.id][calc.rt] = calc;
        //     //если в строке с исследованиями отрисовались все 3 исследования и вернулись значения по ним, то можно считать
        //     // итоговый процент завершения исследования
        //     if(this.totals[calc.id]['operation'] && this.totals[calc.id]['probe'] && this.totals[calc.id]['labtest']) {
        //       this.calculateTotal(calc.id);
        //     }
        //   }
        // },
        // calculateTotal(rowId) {
        //   let oTotal = 0; //полевые операции
        //   let pTotal = 0; //отбор проб
        //   let lTotal = 0; //лаб. илсседования
        //   let rTotal = 0; //финальный отчет готов
        //   let row = this.totals[rowId];
        //   if(row['operation'].t > 0) {
        //     oTotal = 20 / row['operation'].t * row['operation'].c;
        //   }
        //   if(row['probe'].t > 0) {
        //     pTotal = 30 / row['probe'].t * row['probe'].c;
        //   }
        //   if(row['labtest'].t > 0) {
        //     lTotal = 40 / row['labtest'].t * row['labtest'].c;
        //   }
        //   if(this.finalReports[rowId] || row['labtest'].isLastLabTestFinished) {
        //     this.totals[rowId].total = 100;
        //   } else {
        //     this.totals[rowId].total = Math.round(oTotal + pTotal + lTotal + rTotal);
        //   }
        //   this.calculatedTotals++;
        //   if(this.calculatedTotals == this.researches.length) {
        //     this.isCalculatedTotals=true;
        //     // this.calculateTotalCounter();
        //     setTimeout(() => {
        //       this.calculateTotalCounter();
        //     }, 500)
        //   }
        // },
        // calculateTotalCounter() {
        //   for(let key in this.totals) {
        //     if(this.isFinishedRow(this.totals[key])) {
        //       this.finishedCount++;
        //     }
        //   }
        // },
    },
    created() {
      this.opened();
      // this.initScrollBars();
    },
    // beforeDestroy() {
    //   // FOR perfect-scrollbar
    //   window.removeEventListener('resize', this.updateScollBars);
    //   console.log('beforeDestroy')
    // },
    components: {
        'command-user': CommandUser,
        'research-dialog': ResearchDialog,
        'research-row': ResearchRow,
        'research-info': ResearchInfo,
        'survey-notifier': SurveyNotifier,
        'SyrveyNewCardQuaterWrapper': SyrveyNewCardQuaterWrapper
    },
    watch: {
        currentUser: {
            handler: function (val) {
                if(val) {
                    this.opened();
                }
            }
        }
    }
};
</script>

<style src="./Research.css" scoped></style>
