<template>
    <div class="chart-row chart-card chart-card-one">
      <el-row></el-row>
      <el-row class="chart-headers-row">
        <el-col :span="10">
          <div class="chart-header">{{$t('researchInfoForm.str1')}}</div>
        </el-col>

        <el-col :span="4">
          <div  style="width: 100;">
            <ProbePacketSelect :rows="rows" :title="$t('researchInfoForm.str2')" :isShowByCenter="true" :isShowPacket="true"
                @event-probepacket-selected="probepacketSelected"></ProbePacketSelect>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="chart-filter">
            <div class="one-row cf-header">{{$t('researchInfoForm.str3')}}</div>
            <div class="one-row cf-value cf-value-1 clickable" v-bind:class="{'cf-active': filterActive == BAR_FILTER.PERCENTS}" @click="setFilter(BAR_FILTER.PERCENTS)"> 
              <span v-bind:class="{'link-text': filterActive != BAR_FILTER.PERCENTS}">{{$t('researchInfoForm.str4')}}</span>
            </div>
            <div class="one-row cf-value cf-value-1 clickable" v-bind:class="{'cf-active': filterActive == BAR_FILTER.VALUES}" @click="setFilter(BAR_FILTER.VALUES)"> 
              <span v-bind:class="{'link-text': filterActive != BAR_FILTER.VALUES}">{{$t('researchInfoForm.str5')}}</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="chart-headers-row">
        <el-col :span="10">
          <CompositionLegend 
            :checkedOptions="initialCompositionState"
            @event-research-legend-set-value="legendSelectFilter"></CompositionLegend>
        </el-col>
      </el-row>
      
      <div class="sort-chart-icon sort-icon-position clickable" @click="sortX(false)"></div>
      <!--  
        INFO-IMP: перенести в компонент v-chart если надо будет делать зелёную заливку фона под мышкой
        @mouseover="setAreaStyleColor" 
      -->
      <v-chart class="chart" :option="option" autoresize ref="echart"/>
    </div>
</template>

<script>
import VChart from "vue-echarts";
import { CanvasRenderer } from "echarts/renderers";
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DatasetComponent
} from "echarts/components";


// import serverResearch from "../../api/server-research";
import * as chConsts from './ChartHelpers/ChartConstants';
import chHelpers from './ChartHelpers/ChartHelpers';
import CompositionLegend from './componets/CompositionLegend.vue';
import ProbePacketSelect from './componets/ProbePacketSelect.vue';

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DatasetComponent
]);

export default {
  props: ['gsId', 'rows'],
  data() {
    return {
      currentRow: {},
      isShowPacpetDialog: false,
      // isShowRadoi: false,
      // radio: null,
      filterActive: '',
      orderXAsc: false,
      compositionState: {}, //состояние выбрвнных чекбоксов на легенде
      initialCompositionState: [], //начальное состояние чекбоксов на легенде. расчитывается по данным и потом не должно меняться
      chartMax: 0,
      chartMaxAbsolute: 0,
      option: chHelpers.copyOptions(this.$t('researchInfoForm.axisName1'), this.chartMax), // chHelpers.copyOptions() //chConsts.OPTIONS
      BAR_FILTER: chConsts.BAR_FILTER,
    };
  },
  methods: {
    init() {
      this.setFilter(chConsts.BAR_FILTER.PERCENTS);
      if(this.rows && this.rows[0]) {
        this.currentRow = this.rows[0];
        this.radio = this.currentRow.probepacket;
        let chartData = this.getDataForChart(this.currentRow);
        if(chartData) {
          this.option.dataset.source = chartData;
          this.option.xAxis[0].axisLabel.formatter = this.xAxisFormatter;
          chHelpers.setSeriesLabelsSetup(this.option);
          this.initialCompositionState = chHelpers.defineCompositionLegendCheckedOptions(chartData);
        }
      } else {
        this.option.dataset.source = [];
      }
    },
    getDataForChart(row) {
      if(row.modeee && row.modeee.documentReport_Markers) {
        this.chartMax = row.modeee.max;
        this.chartMaxAbsolute = row.modeee.maxAbsolute;
        return row.modeee.documentReport_Markers
      } else {
        this.chartMax = 0;
        this.chartMaxAbsolute = 0;
        return []
      }
    },
    xAxisFormatter(name) {
      //форматтер для подписи нижней оси Х
      if(this.option.dataset.source && this.option.dataset.source.length > chConsts.BAR_TO_MATCH) {
        return name;
      }
      return this.$t('researchInfoForm.port') + ' ' + name;
    },
    //метод вызывается при смене Пакета в шапке графика (тот, который с радиокнопками и датами)
    probepacketSelected(row) {
      this.currentRow = row;
      let chartData = this.getDataForChart(this.currentRow);
      if(chartData) {
        this.option.dataset.source = chartData; //this.currentRow.documentreport_markers;
      } else {
        this.option.dataset.source = [];
      }
      this.setAxisYParams();
      chHelpers.calculateTotalForSecondAxisX(this.option, this.compositionState, this.filterActive);
      this.sortX(true);
    },
    sortX(isNotChangeDirection) {
      if(!isNotChangeDirection) {
        this.orderXAsc = !this.orderXAsc;
      }
      let temp = [];
      Object.assign(temp, this.option.dataset.source);
      temp.sort((a,b) => {
        if(this.orderXAsc) {
          return a.port - b.port;
        } else {
          return b.port - a.port;
        }
      });
      this.option.dataset.source = temp;
      chHelpers.calculateTotalForSecondAxisX(this.option, this.compositionState, this.filterActive);
      // chHelpers.setAxisYLabels(this.compositionState.filterG, this.filterActive, this.option, this.chartMax, this.chartMaxAbsolute)
    },
    setFilter(f) {
      if(f == chConsts.BAR_FILTER.PERCENTS) {
        this.option.yAxis.name = this.$t('researchInfoForm.axisName1');
      } else {
        this.option.yAxis.name = this.$t('researchInfoForm.axisName2');
      }
      this.filterActive = f;
      this.option.tooltip.formatter = this.formatter;
      this.option.dataset.dimensions = ['port', chConsts.BAR_FIELDS[this.filterActive].OIL, chConsts.BAR_FIELDS[this.filterActive].WATER]
      chHelpers.calculateTotalForSecondAxisX(this.option, this.compositionState, this.filterActive);
      this.setChartData(this.compositionState);
    },
    formatter(data) {
      //TODO: исправть, что бы отображал в зависимости от выбранных чекбоксов на легенде, см на ResearchInfoChartTwo.js
      //форматтер для всплывающих подсказок
      return `<span style="color: ${chConsts.BAR_COLORS.OIL};">${data.data[chConsts.BAR_FIELDS[this.filterActive].OIL]}</span> <span style="color: ${chConsts.BAR_COLORS.WATER};">${data.data[chConsts.BAR_FIELDS[this.filterActive].WATER]}</span>`;
    },
    //при смене выбрабнных типов жидкости/газа надо убрать/добавить колонки на график
    legendSelectFilter(compositionState) {
      this.compositionState = compositionState;
      this.setChartData(compositionState);
    },
    clearChartRef() {
      if(this.$refs.echart._isMounted) {
        this.$refs.echart.clear();
      }
    },
    setChartData(compositionState) {
      this.clearChartRef();
      chHelpers.setDimensionsAndSeries(this.option, this.filterActive, compositionState);
      this.sortX(true);
      this.setAxisYParams();
    },
    //Устанавливает надписи и другие параметры для одной/двух осей Y в зависимости от выбранных чекбоксов в легенде и прочих параметров
    setAxisYParams() {
      chHelpers.setAxisYLabels(this.compositionState.filterG, this.filterActive, this.option, this.chartMax, this.chartMaxAbsolute);
    }
    //INFO-IMP: если надо будет делать зелёную заливку фона под мышкой
    // setAreaStyleColor(event) {
    //   let len = this.option.dataset.source.length;
    //   let temp = [];
    //   let idx = this.option.dataset.source.findIndex(el => {
    //     return el.id == event.data.id
    //   });
    //   for(let i = 0; i< len; i++) {
    //     if(i == idx) {
    //       temp.push('#dff1da')
    //     } else {
    //       temp.push('white');
    //     }
    //   }
    //   this.option.xAxis[0].splitArea.areaStyle.color = temp;
    // }
  },
  components: {
    VChart, CompositionLegend, ProbePacketSelect
  },
  mounted() {
    if(this.option && this.option.dataset) {
      this.option.dataset.source = [];
    }
  },
  watch: {
    'rows': {
        handler: function (val, old) {
          if(val && val[0] && !old[0]) {
            this.init();
          } 
        }
    }
  }
};
</script>

<style src="./ResearchInfoChart.css" scoped></style>
<style scoped>
.chart-headers-row {
  margin: 30px 30px 0 50px;
}
</style>